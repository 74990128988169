export const postMessageTypes = {
  toggle: 'toggle',
  open: 'open',
  reservation: 'reservation',
  setup: 'setup',
  config: 'config',
  ready: 'ready',
  gtmEvent: 'gtmEvent',
};

const createPostMessage = (type, payload) => ({
  type: type in postMessageTypes
    ? type
    : 'unknown',
  payload,
});

export default createPostMessage;
