import { useTranslation } from 'react-i18next';

import ErrorIcon from '../../images/error.svg';

import './styles.scss';

export default ({ processName } : { processName: string }): JSX.Element => {
  const { t } = useTranslation();

  const processFailedLabel = processName === 'booking'
    ? t('booking_has_failed') : t('payment_has_failed');

  return (
    <div className="container-booker-error">
      <div className="error">
        <img
          src={ErrorIcon}
          title="Error Icon"
          alt="Error Icon"
          className="error-icon"
        />
        <h2>{t('something_went_wrong')}</h2>
        <p>{processFailedLabel}</p>
      </div>
    </div>
  );
};
