import { postTrackingEvent, TrackingEventEnum } from '../utils/analytics';
import useReduxKey from './useReduxKey';

type TrackEventFunc = (event: TrackingEventEnum, data?: Record<string, unknown>) => void;
type UseAnalyticsHook = {
  trackEvent: TrackEventFunc;
}

export default function useAnalytics(): UseAnalyticsHook {
  const { pageId, parentOrigin } = useReduxKey('configuration');

  return {
    trackEvent(event: TrackingEventEnum, data: Record<string, unknown> = {}): void {
      postTrackingEvent(event, parentOrigin, {
        page: pageId,
        ...data,
      });
    },
  };
}
