import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

const PaymentRedirect = (): ReactElement => {
  const { t } = useTranslation();

  return (
    <div className="payment-redirect-container">
      <div className="payment-redirect-primary-message">
        {t(
          'payment_redirect_message',
          'You will be redirected to the payment platform shortly...'
        )}
      </div>
      <div className="payment-redirect-spinner" />
      <div className="payment-redirect-secondary-message">
        {t('payment_do_not_close_page', 'Please do not close this page.')}
      </div>
    </div>
  );
};

export default PaymentRedirect;
