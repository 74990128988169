import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import resourceActions from '../actions/resourceActions';
import useReduxKey from './useReduxKey';
import filterActions from '../actions/filterActions';
import { Nullable } from '../types';
import useAnalytics from './useAnalytics';
import { TrackingEventEnum } from '../utils/analytics';
import { RootState } from '../reducers';

interface LocationOptions {
  id: string;
  name: string;
  select: () => void;
  isSelected: boolean;
  // eslint-disable-next-line camelcase
  resource_type_name: string;
}

export interface UseLocationsReturn {
  options: Nullable<LocationOptions[][]>;
  selected: any;
}

export interface ResourceTree {
  children: Nullable<ResourceTreeNode[]>,
}

export interface ResourceTreeNode extends ResourceTree {
  id: string;
  name: string;
  bookable: boolean;
  // eslint-disable-next-line camelcase
  resource_type_name: string;
  // eslint-disable-next-line camelcase
  base_price: number;
  // eslint-disable-next-line camelcase
  base_price_incl: number;
  capacity: number | null;
  context: string;
  // eslint-disable-next-line camelcase
  default_price: number | null;
  description: string;
  image: string;
  // eslint-disable-next-line camelcase
  is_active: boolean;
  // eslint-disable-next-line camelcase
  is_virtual: boolean;
  // eslint-disable-next-line camelcase
  max_capacity: number | null;
  // eslint-disable-next-line camelcase
  number_of_active_layouts: string;
  parent: string;
  // eslint-disable-next-line camelcase
  resource_type: string;
  timezone: string;
  visible: boolean;
  vat: number
  // eslint-disable-next-line camelcase
  vat_rate: number
  features: string | null
  // eslint-disable-next-line camelcase
  keep_in_mind: string | null
}

interface MappedResourceTreeNode extends Omit<ResourceTreeNode, 'children'> {
  parent: string;
}

interface ResourceMap {
  [id: string]: MappedResourceTreeNode;
}

const subTreeHas = (resourceTreeNode: ResourceTreeNode,
  condition: (treeNode) => boolean | undefined): boolean => {
  const result = condition(resourceTreeNode);

  if (result !== undefined) {
    return result;
  }

  if (resourceTreeNode.children === null) {
    return false;
  }

  return resourceTreeNode.children.reduce((currentResult, childNode) => (
    currentResult || subTreeHas(childNode, condition)
  ), false);
};

const subTreeHasBookableResources = (node: ResourceTreeNode) =>
  subTreeHas(node, ({ bookable, children }) => {
    if (bookable) {
      return true;
    }
    return undefined;
  });

const subTreeHasPathTo = (node: ResourceTreeNode, resourceId: string) =>
  subTreeHas(node, ({ id }) => {
    if (resourceId === id) {
      return true;
    }
    return undefined;
  });

const getPath = (id: string, resourceMap: ResourceMap): string[] => {
  let node = resourceMap[id];
  const path = [];

  do {
    path.unshift(node.id);
    node = resourceMap[node.parent];
  } while (node);

  return path;
};

export default (defaultLocation?: string): UseLocationsReturn => {
  const { fetchResources } = resourceActions();
  useEffect(() => {
    fetchResources();
  }, []);

  const [selectedOptions, setSelectedOptons] = useState<string[]>([]);
  const [options, setOptions] = useState<LocationOptions[][]>([]);

  const { resourceTree, resourceMap } = useReduxKey('resources');
  const { trackEvent } = useAnalytics();
  const selected = useMemo(() => {
    const lastItem = options[options.length - 1];

    const selectedLastItem = lastItem?.find(({ isSelected }) => isSelected);

    if (!selectedLastItem) {
      return null;
    }
    const resource = resourceMap[selectedLastItem.id];

    return {
      ...resource,
      children: Object.values(resourceMap).filter(({ bookable, parent }) => (
        bookable && parent === resource.id
      )),
    };
  }, [options]);

  const { selectLocation } = filterActions();
  useEffect(() => {
    selectLocation(selected);
    trackEvent(TrackingEventEnum.SCHEDULE_LOCATION_SELECTED, {
      location: selected,
    });
  }, [selected]);

  useEffect(() => {
    trackEvent(TrackingEventEnum.SCHEDULE_LOCATION_FILTER_CHANGED, {
      path: selectedOptions,
    });
  }, [selectedOptions]);

  const generateOptions = useCallback(
    (resourceTreeNode: ResourceTree | ResourceTreeNode,
      newOptions: LocationOptions[][] = []): LocationOptions[][] => {
      if (!(resourceTreeNode && resourceTreeNode.children)) {
        return newOptions;
      }

      const children = resourceTreeNode.children
        .filter((node) => !node.bookable && subTreeHasBookableResources(node));

      if (children.length === 0) {
        return newOptions;
      }

      const nextOption = children
        // eslint-disable-next-line camelcase
        .map(({ id, name, resource_type_name }) => ({
          id,
          name,
          select: () => setSelectedOptons((newSelectedOptions) => [
            ...newSelectedOptions.slice(0, newOptions.length),
            id,
          ]),
          isSelected: selectedOptions[newOptions.length] === id,
          resource_type_name,
        }));

      const nextValue = nextOption.find(({ isSelected }) => isSelected);
      const returnValue = [
        ...newOptions,
        nextOption,
      ];

      if (nextValue) {
        const next = children.find(({ id }) => id === nextValue.id);

        return generateOptions(next, returnValue);
      }

      return returnValue;
    }, [selectedOptions]);

  useEffect(() => {
    if (resourceTree) {
      setOptions(generateOptions({ children: resourceTree }));
    }
  }, [resourceTree, selectedOptions]);

  useEffect(() => {
    if (options[selectedOptions.length] && options[selectedOptions.length].length === 1) {
      options[selectedOptions.length][0].select();
    }
  }, [selectedOptions, options]);

  useEffect(() => {
    if (defaultLocation && defaultLocation in resourceMap) {
      setSelectedOptons(getPath(defaultLocation, resourceMap));
    }
  }, [resourceMap, defaultLocation]);

  const calculateDefaultSelected = useCallback(
    (resourceTreeNode: ResourceTree | ResourceTreeNode, location: string): string[] => {
      const isValidNode = (resourceTreeNode as ResourceTreeNode).id !== undefined;

      if (isValidNode && location === (resourceTreeNode as ResourceTreeNode).id) {
        return [location];
      }

      const next = resourceTreeNode.children.find(
        (child) => subTreeHasPathTo(child, location));
      if (next) {
        const path = isValidNode ? [(resourceTreeNode as ResourceTreeNode).id] : [];
        return [
          ...path,
          ...calculateDefaultSelected(next, location),
        ];
      }

      return [];
    }, []);

  const selectedLocation = useSelector(({ filterState }: RootState) =>
    filterState.selectedLocation);
  useEffect(() => {
    if (selectedLocation) {
      const selectedPath = calculateDefaultSelected({ children: resourceTree },
        selectedLocation.id);
      if (selectedOptions.length !== selectedPath.length) {
        setSelectedOptons(selectedPath);
      }
    }
  }, [selectedLocation, resourceTree]);

  const selectableOptions = useMemo(() => options.slice(1), [options]);

  return { options: selectableOptions, selected };
};
