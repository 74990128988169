import useActions from '../hooks/useActions';
import user from '../reducers/user';

export default () => useActions({
  setUserInteractedWithTheWidget: (interactedWithTheWidget) => async (dispatch) => {
    dispatch({
      type: user.types.setUserInteractedWithTheWidget,
      payload: interactedWithTheWidget,
    });
  },
});
