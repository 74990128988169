
export default async function loadLocale(locale: string | undefined): Promise<any> {
  try {
    switch (locale) {
      case 'en': {
        const [dayJsLocale, dateFnsLocale] = await Promise.all([
          import('dayjs/locale/en'),
          import('date-fns/locale/en-GB'),
        ]);
        return { dayJsLocale, dateFnsLocale };
      }
      case 'nl': {
        const [dayJsLocaleNl, dateFnsLocaleNl] = await Promise.all([
          import('dayjs/locale/nl'),
          import('date-fns/locale/nl'),
        ]);
        return { dayJsLocaleNl, dateFnsLocaleNl };
      }
      case 'es': {
        const [dayJsLocaleEs, dateFnsLocaleEs] = await Promise.all([
          import('dayjs/locale/es'),
          import('date-fns/locale/es'),
        ]);
        return { dayJsLocaleEs, dateFnsLocaleEs };
      }
      case 'fr': {
        const [dayJsLocaleFr, dateFnsLocaleFr] = await Promise.all([
          import('dayjs/locale/fr'),
          import('date-fns/locale/fr'),
        ]);
        return { dayJsLocaleFr, dateFnsLocaleFr };
      }
      case 'de': {
        const [dayJsLocaleDe, dateFnsLocaleDe] = await Promise.all([
          import('dayjs/locale/de'),
          import('date-fns/locale/de'),
        ]);
        return { dayJsLocaleDe, dateFnsLocaleDe };
      }
      case 'pl': {
        const [dayJsLocalePl, dateFnsLocalePl] = await Promise.all([
          import('dayjs/locale/pl'),
          import('date-fns/locale/pl'),
        ]);
        return { dayJsLocalePl, dateFnsLocalePl };
      }
      case 'ro': {
        const [dayJsLocaleRo, dateFnsLocaleRo] = await Promise.all([
          import('dayjs/locale/ro'),
          import('date-fns/locale/ro'),
        ]);
        return { dayJsLocaleRo, dateFnsLocaleRo };
      }
      case 'sv': {
        const [dayJsLocaleSv, dateFnsLocaleSv] = await Promise.all([
          import('dayjs/locale/sv'),
          import('date-fns/locale/sv'),
        ]);
        return { dayJsLocaleSv, dateFnsLocaleSv };
      }
      case 'ru': {
        const [dayJsLocaleRu, dateFnsLocaleRu] = await Promise.all([
          import('dayjs/locale/ru'),
          import('date-fns/locale/ru'),
        ]);
        return { dayJsLocaleRu, dateFnsLocaleRu };
      }
      case 'tr': {
        const [dayJsLocaleTr, dateFnsLocaleTr] = await Promise.all([
          import('dayjs/locale/tr'),
          import('date-fns/locale/tr'),
        ]);
        return { dayJsLocaleTr, dateFnsLocaleTr };
      }
      case 'fa': {
        const [dayJsLocaleFa, dateFnsLocaleFa] = await Promise.all([
          import('dayjs/locale/fa'),
          import('date-fns/locale/fa-IR')
        ]);
        return { dayJsLocaleFa, dateFnsLocaleFa };
      }
      case 'ja': {
        const [dayJsLocaleJa, dateFnsLocaleJa] = await Promise.all([
          import('dayjs/locale/ja'),
          import('date-fns/locale/ja'),
        ]);
        return { dayJsLocaleJa, dateFnsLocaleJa };
      }
      case 'vi': {
        const [dayJsLocaleVi, dateFnsLocaleVi] = await Promise.all([
          import('dayjs/locale/vi'),
          import('date-fns/locale/vi'),
        ]);
        return { dayJsLocaleVi, dateFnsLocaleVi };
      }
      case 'zh': {
        const [dayJsLocaleZh, dateFnsLocaleZh] = await Promise.all([
          import('dayjs/locale/zh'),
          import('date-fns/locale/zh-CN')
        ]);
        return { dayJsLocaleZh, dateFnsLocaleZh };
      }
      case 'pt': {
        const [dayJsLocalePt, dateFnsLocalePt] = await Promise.all([
          import('dayjs/locale/pt'),
          import('date-fns/locale/pt'),
        ]);
        return { dayJsLocalePt, dateFnsLocalePt };
      }
      case 'ar': {
        const [dayJsLocaleAr, dateFnsLocaleAr] = await Promise.all([
          import('dayjs/locale/ar'),
          import('date-fns/locale/ar'),
        ]);
        return { dayJsLocaleAr, dateFnsLocaleAr };
      }
      case 'it': {
        const [dayJsLocaleIt, dateFnsLocaleIt] = await Promise.all([
          import('dayjs/locale/it'),
          import('date-fns/locale/it'),
        ]);
        return { dayJsLocaleIt, dateFnsLocaleIt };
      }
      case 'id': {
        const [dayJsLocaleId, dateFnsLocaleId] = await Promise.all([
          import('dayjs/locale/id'),
          import('date-fns/locale/id'),
        ]);
        return { dayJsLocaleId, dateFnsLocaleId };
      }
      case 'el': {
        const [dayJsLocaleEl, dateFnsLocaleEl] = await Promise.all([
          import('dayjs/locale/el'),
          import('date-fns/locale/el'),
        ]);
        return { dayJsLocaleEl, dateFnsLocaleEl };
      }
      case 'ko': {
        const [dayJsLocaleKo, dateFnsLocaleKo] = await Promise.all([
          import('dayjs/locale/ko'),
          import('date-fns/locale/ko'),
        ]);
        return { dayJsLocaleKo, dateFnsLocaleKo };
      }
      case 'th': {
        const [dayJsLocaleTh, dateFnsLocaleTh] = await Promise.all([
          import('dayjs/locale/th'),
          import('date-fns/locale/th'),
        ]);
        return { dayJsLocaleTh, dateFnsLocaleTh };
      }
      case 'uk': {
        const [dayJsLocaleUk, dateFnsLocaleUk] = await Promise.all([
          import('dayjs/locale/uk'),
          import('date-fns/locale/uk'),
        ]);
        return { dayJsLocaleUk, dateFnsLocaleUk };
      }
      case 'he': {
        const [dayJsLocaleHe, dateFnsLocaleHe] = await Promise.all([
          import('dayjs/locale/he'),
          import('date-fns/locale/he'),
        ]);
        return { dayJsLocaleHe, dateFnsLocaleHe };
      }
      case 'cs': {
        const [dayJsLocaleCs, dateFnsLocaleCs] = await Promise.all([
          import('dayjs/locale/cs'),
          import('date-fns/locale/cs'),
        ]);
        return { dayJsLocaleCs, dateFnsLocaleCs };
      }
      case 'sr': {
        const [dayJsLocaleSr, dateFnsLocaleSr] = await Promise.all([
          import('dayjs/locale/sr'),
          import('date-fns/locale/sr'),
        ]);
        return { dayJsLocaleSr, dateFnsLocaleSr };
      }
      case 'hu': {
        const [dayJsLocaleHu, dateFnsLocaleHu] = await Promise.all([
          import('dayjs/locale/hu'),
          import('date-fns/locale/hu'),
        ]);
        return { dayJsLocaleHu, dateFnsLocaleHu };
      }
      case 'da': {
        const [dayJsLocaleDa, dateFnsLocaleDa] = await Promise.all([
          import('dayjs/locale/da'),
          import('date-fns/locale/da'),
        ]);
        return { dayJsLocaleDa, dateFnsLocaleDa };
      }
      case 'bg': {
        const [dayJsLocaleBg, dateFnsLocaleBg] = await Promise.all([
          import('dayjs/locale/bg'),
          import('date-fns/locale/bg'),
        ]);
        return { dayJsLocaleBg, dateFnsLocaleBg };
      }
      case 'fi': {
        const [dayJsLocaleFi, dateFnsLocaleFi] = await Promise.all([
          import('dayjs/locale/fi'),
          import('date-fns/locale/fi'),
        ]);
        return { dayJsLocaleFi, dateFnsLocaleFi };
      }
      case 'sk': {
        const [dayJsLocaleSk, dateFnsLocaleSk] = await Promise.all([
          import('dayjs/locale/sk'),
          import('date-fns/locale/sk'),
        ]);
        return { dayJsLocaleSk, dateFnsLocaleSk };
      }
      case 'hr': {
        const [dayJsLocaleHr, dateFnsLocaleHr] = await Promise.all([
          import('dayjs/locale/hr'),
          import('date-fns/locale/hr'),
        ]);
        return { dayJsLocaleHr, dateFnsLocaleHr };
      }
      case 'hi': {
        const [dayJsLocaleHi, dateFnsLocaleHi] = await Promise.all([
          import('dayjs/locale/hi'),
          import('date-fns/locale/hi'),
        ]);
        return { dayJsLocaleHi, dateFnsLocaleHi };
      }
      case 'lt': {
        const [dayJsLocaleLt, dateFnsLocaleLt] = await Promise.all([
          import('dayjs/locale/lt'),
          import('date-fns/locale/lt'),
        ]);
        return { dayJsLocaleLt, dateFnsLocaleLt };
      }
      case 'nb': {
        const [dayJsLocaleNb, dateFnsLocaleNb] = await Promise.all([
          import('dayjs/locale/nb'),
          import('date-fns/locale/nb'),
        ]);
        return { dayJsLocaleNb, dateFnsLocaleNb };
      }
      case 'sl': {
        const [dayJsLocaleSl, dateFnsLocaleSl] = await Promise.all([
          import('dayjs/locale/sl'),
          import('date-fns/locale/sl'),
        ]);
        return { dayJsLocaleSl, dateFnsLocaleSl };
      }
      case 'et': {
        const [dayJsLocaleEt, dateFnsLocaleEt] = await Promise.all([
          import('dayjs/locale/et'),
          import('date-fns/locale/et'),
        ]);
        return { dayJsLocaleEt, dateFnsLocaleEt };
      }
      case 'lv': {
        const [dayJsLocaleLv, dateFnsLocaleLv] = await Promise.all([
          import('dayjs/locale/lv'),
          import('date-fns/locale/lv'),
        ]);
        return { dayJsLocaleLv, dateFnsLocaleLv };
      }
      default:
        throw new Error('Unsupported locale');
    }
  } catch (error) {
    console.error(error);
  }
}
