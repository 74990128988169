import React, {
  forwardRef,
  ReactNode,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { ReactSVG } from 'react-svg';
import classNames from 'classnames';
import useReduxKey from '../../hooks/useReduxKey';
import arrowIcon from '../../images/chevron-down.svg';

import './style.scss';

type Props = {
  icon: string;
  label: string | JSX.Element;
  children: ReactNode;
  disabled?: boolean;
  hidden?: boolean;
};

type setStateFunc<S> = React.Dispatch<React.SetStateAction<S>>;
export type AccordionButtonRef = {
  setOpen: setStateFunc<boolean>;
};

const AccordionButton = forwardRef<AccordionButtonRef, Props>(
  ({ icon, label, children, disabled, hidden = false }, ref) => {
    const [open, setOpen] = useState(false);
    const user = useReduxKey('user');

    useImperativeHandle(ref, () => ({
      setOpen,
    }));

    useEffect(() => {
      if (open) {
        const container = document.querySelector(
          '.accordion-container--hidden',
        );

        if (container !== null && user.interactedWithTheWidget) {
          container.scrollIntoView({ behavior: 'smooth' });
        }
      }
    }, [open]);

    return (
      <>
        <button
          type="button"
          className={classNames({
            'accordion-button': true,
            'accordion-button--open': open,
            'accordion-button--hidden': hidden,
          })}
          disabled={disabled}
          onClick={() => setOpen(!open)}
        >
          <ReactSVG src={icon} />
          {label}
          <span className={open ? 'icon-down' : ''}>
            <ReactSVG src={arrowIcon} />
          </span>
        </button>
        <div
          className={classNames({
            'accordion-container': true,
            'accordion-container--hidden': !open,
          })}
        >
          {children}
        </div>
      </>
    );
  },
);

export default AccordionButton;
