import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import filterActions from '../../actions/filterActions';

import Button from '../Button/Button';

import './styles.scss';

export default () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { resetFilterValues } = filterActions();

  function handleOnClickBack() {
    resetFilterValues();
    navigate('/');
  }

  return (
    <div className="sticky-footer">
      <Button
        onClick={() => handleOnClickBack()}
        label={t('book_another_space')}
        className="button--primary button--large"
      />
    </div>
  );
};
