import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import AccordionButton from '../AccordionButton/AccordionButton';
import ReservationTypeIcon from '../../images/octagon.svg';

import './style.scss';
import filterActions from '../../actions/filterActions';
import useReduxKey from '../../hooks/useReduxKey';
import useAnalytics from '../../hooks/useAnalytics';
import { TrackingEventEnum } from '../../utils/analytics';
import { FilterState } from '../../reducers/filter';

type ReservationType = {
  // eslint-disable-next-line camelcase
  reservation_type: string;
  enabled: boolean;
  name: string;
}

type ReservationTypePickerProps = {
  reservationTypes: ReservationType[];
  showDetailsOnStart: boolean;
  disabled: boolean;
}

type ButtonRef = {
  setOpen: (open: boolean) => void;
}

export default ({
  reservationTypes,
  showDetailsOnStart,
  disabled,
}: ReservationTypePickerProps): JSX.Element => {
  const [selectedType, setSelectedType] = useState<string>('');
  const filterState = useReduxKey<FilterState>('filterState');
  const { trackEvent } = useAnalytics();
  const { t } = useTranslation();
  const buttonRef = useRef<ButtonRef>();
  const { setReservationType } = filterActions();

  useEffect(() => {
    const button = buttonRef.current ?? null;
    button.setOpen(showDetailsOnStart);
    if (showDetailsOnStart) {
      trackEvent(TrackingEventEnum.SCHEDULE_RESERVATION_TYPE);
    }
  }, [showDetailsOnStart, buttonRef.current]);

  function selectReservationType(reservationType) {
    setSelectedType(reservationType);
    setReservationType(reservationType);
    trackEvent(TrackingEventEnum.SCHEDULE_RESERVATION_TYPE_SELECTED, {
      reservation_type: reservationType,
    });
  }

  function renderTypeHeader() {
    const { reservationType } = filterState;
    if (reservationType === null || reservationType === '') {
      return t('which_type', 'Which type?');
    }
    const type = reservationTypes.find((rt) => rt.reservation_type === reservationType);
    if (type) {
      return t(type.name.toLowerCase().replace(/ /g,"_"), type.name);
    }
    return t('unknown_type', 'Unknown type');
  }

  function renderTypeList() {
    return (
      <>
        <ul className="reservation-types-list">
          {reservationTypes
            .map(({ reservation_type: reservationType, name }) => (
              <li
                key={`type_${reservationType}`}
                className={reservationType === selectedType ? 'is-active' : 'is-not-active'}
                onClick={() => selectReservationType(reservationType)}
              >
                {t(name.toLowerCase().replace(/ /g,"_"), name)}
              </li>
            ))}
        </ul>
      </>
    );
  }

  return (
    <div className="reservation-types-container">
      <AccordionButton
        ref={buttonRef}
        label={renderTypeHeader()}
        icon={ReservationTypeIcon}
        disabled={disabled}
      >
        {renderTypeList()}
      </AccordionButton>
    </div>
  );
};
