import createReducer from '../utils/createReducer';

export interface Details {
  form: Record<string, unknown>;
  notes: string
}

const INITAL_STATE: Details = {
  form: {},
  notes: '',
};

export default createReducer('details', INITAL_STATE, {
  setForm: (state, payload) => ({ ...state, form: payload }),
  setNotes: (state, payload) => ({ ...state, notes: payload }),
  reset: () => INITAL_STATE,
});
