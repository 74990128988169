import createReducer from '../utils/createReducer';

const INITIAL_STATE = null;

export default createReducer('reservationDetail', INITIAL_STATE, {
  set: (state, payload) => payload,
  update: (state, payload) => ({
    ...state,
    ...payload,
  }),
});
