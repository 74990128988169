import React from 'react';
import { useTranslation } from 'react-i18next';
import useReduxKey from '../../hooks/useReduxKey';

import Price from '../../components/Price/Price';
import Button from '../../components/Button/Button';

import './card.scss';

const Card = ({ space, onClick }) => {
  const { showPayments, showPriceInclVat } = useReduxKey('configuration');
  const { t } = useTranslation();

  return (
    <div className="card" onClick={onClick}>
      {space.image && (
        <>
          <div className="card-header">
            <div className="card-info">
              <h2>{space.name}</h2>
              {space.max_capacity !== null && (
                <span className="capacity">
                  {space.max_capacity} {t('people')}
                </span>
              )}
            </div>
          </div>
          <img
            src={space.image}
            alt={space.name}
            className="img-responsive img-fit-contain ghost-place-holder"
          />
        </>
      )}
      <div className={space.image ? 'card-footer' : 'card-footer__no-img'}>
        <div className="card-info">
          <h2>{space.name}</h2>
          {space.max_capacity !== null && (
            <span className="capacity">
              {space.max_capacity} {t('people')}
            </span>
          )}
        </div>
        <div className="price-button-container">
          {showPayments && (
            <>
              <span className="price">
                <Price value={showPriceInclVat ? space.base_price_incl : space.base} />
              </span>
            </>
          )}
          <Button
            onClick={onClick}
            label={t('view_details')}
            className="button--small button--grey"
          />
        </div>
      </div>
    </div>
  );
};

export default Card;
