import { ReactElement, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { NavigateFunction } from 'react-router-dom';

import useReduxKey from '../../hooks/useReduxKey';

import Price from '../Price/Price';
import Button from '../Button/Button';
import ArrowNext from '../../images/arrow-next.svg';
import GenericStickyFooter from '../GenericStickyFooter';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';

import './styles.scss';
import { PriceContext } from '../../contexts/PriceProvider';

interface StickyFooterProps {
  next?: string;
  navigate? : NavigateFunction;
  nextStep?: number;
  disableNext?: boolean;
  actionCallback?: () => void;
  label?: string;
  paymentWindowRef?: React.RefObject<Window>;
}

const SpaceStickyFooter = ({
  next, nextStep, navigate,
  disableNext, actionCallback, label = 'next', paymentWindowRef
}: StickyFooterProps): ReactElement => {
  const { t } = useTranslation();
  const { additionalServices } = useReduxKey('services');
  const {
    showServicesStep, showPayments, showPriceInclVat,
  } = useReduxKey('configuration');

  const vatLabel = showPriceInclVat ? t('incl_vat') : t('excl_vat');
  const buttonLabel = label === 'next' ? t('next') : label;

  function handleOnClickNextStep() {
    if (actionCallback && next === '/bookingProgress') {
      actionCallback();
      navigate(next, {
        state: {
          step: nextStep,
          paymentWindowRef: paymentWindowRef,
        },
      });
    } else if (actionCallback) {
      actionCallback();
    } else {
      navigate(next, {
        state: {
          step: nextStep,
        },
      });
    }
  }

  const {
    subtotal,
    subtotal_incl: subtotalVat,
    total, total_incl: totalVat,
    servicesSubtotal,
    servicesSubtotalIncl,
  } = useContext(PriceContext);

  const renderPriceBreakdown = (
    showPayments && showServicesStep
    && additionalServices && servicesSubtotal > 0
  );

  let renderPriceValue = showPayments && total;

  return (
    <GenericStickyFooter className="space-sticky-footer">
      <div className="price-info">
        <div className="price-breakdown">
          {renderPriceBreakdown && (
            <>
              <div>{t('space')}: <Price value={showPriceInclVat ? subtotalVat : subtotal} /></div>
              <div>{t('services')}: <Price value={showPriceInclVat ? servicesSubtotalIncl : servicesSubtotal} /></div>
            </>
          )}
        </div>
        {showPayments && (
          <div className="total-price">
            <div className="total">

              {renderPriceValue ? (
                <>
                  <span className="btw">{vatLabel}</span>
                  <span>{`${t('total')} `} </span>
                  <Price value={showPriceInclVat ? totalVat : total} />
                </>
              ) : (
                <>
                  <span className="calculating-subtotal-msg">{t('calculating_subtotal', 'Calculating subtotal...')}</span>
                  <span className="calculating-subtotal-spinner-wrapper">
                    <LoadingSpinner/>
                  </span>
                </>
              )
              }
            </div>
          </div>
        )}
      </div>
      <div>
        <Button
          disabled={disableNext}
          onClick={() => handleOnClickNextStep()}
          label={buttonLabel}
          iconRight={ArrowNext}
          className="button--secondary button--large icon-right"
        />
      </div>
    </GenericStickyFooter>
  );
};

export default SpaceStickyFooter;
