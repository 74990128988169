import React from 'react';

import './styles.scss';

const GenericStickyFooter = ({ className, children }) => (
  <div className={`generic-sticky-footer ${className}`}>
    { children }
  </div>
);

export default GenericStickyFooter;
