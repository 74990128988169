import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import useReduxKey from '../../hooks/useReduxKey';
import { formatDateTime } from '../DateTime';

import './styles.scss';
import { FilterState } from '../../reducers/filter';

const SpaceHeader = ({ onClick }) => {
  const { showGroupSizeStep, displayInResourceTimezone } = useReduxKey('configuration');
  const { localeObjects } = useReduxKey('configuration');
  const { t, i18n } = useTranslation();

  const {
    selectedLocation: location,
    groupSize,
    startDate: date,
    selectedTimeSlot: timeslot,
  } = useReduxKey<FilterState>('filterState');

  const formattingTimezone = displayInResourceTimezone ? location.timezone : dayjs.tz.guess();

  const headerItems = [
    location.name,
    showGroupSizeStep && `${groupSize} ${t('attendees')}`,
    `${formatDateTime(dayjs(date), 'date', localeObjects, i18n.language, formattingTimezone)}`,
    `${formatDateTime(dayjs(timeslot.dStart), 'time', localeObjects, i18n.language, formattingTimezone)} - ${formatDateTime(dayjs(timeslot.dEnd), 'time', localeObjects, i18n.language, formattingTimezone)}`,
  ];

  const subtitle = headerItems.filter((item) => item).join(' / ');

  return (
    <div className="space-header-container">
      <button type="button" className="back-button" onClick={onClick}>Back</button>
      <div className="title">
        <h2>{t('your_selection')}</h2>
        <h3>{subtitle}</h3>
      </div>
    </div>
  );
};

export default SpaceHeader;
