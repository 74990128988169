import { combineReducers } from 'redux';
import configuration from './configuration';
import resources from './resources';
import services from './services';
import timeslots from './timeslots';
import details from './details';
import attendees from './attendees';
import filter from './filter';
import customFields from './customFields';
import reservationDetail from './reservationDetail';
import calendar from './calendar';
import user from './user';
import pricing from './pricing';

const rootReducer = combineReducers({
  filterState: filter,
  customFieldsState: customFields,
  configuration,
  resources,
  services,
  timeslots,
  details,
  attendees,
  reservationDetail,
  calendar,
  user,
  pricing,
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>
export type ReduxKey = keyof RootState
