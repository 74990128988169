import useActions from '../hooks/useActions';
import filter from '../reducers/filter';

type FilterActions = {
  selectLocation: (location) => void;
  deselectLocation: () => void,
  setGroupSize: (groupSize) => void,
  setReservationType: (reservationType) => void,
  setStartDate: (startDate) => void,
  selectTimeSlot: (timeSlot) => void,
  resetSelectedTimeSlot: () => void,
  resetStartDate: () => void,
  selectSpace: (space) => void,
  resetFilterValues: () => void,
  setUnavailableDaysStrings: (days) => void,
}

export default (): FilterActions => useActions({
  selectLocation: (location) => async (dispatch) => {
    dispatch({
      type: filter.types.selectLocation,
      payload: location,
    });
  },
  deselectLocation: () => async (dispatch) => {
    dispatch({
      type: filter.types.deselectLocation,
    });
  },
  setGroupSize: (groupSize) => async (dispatch) => {
    dispatch({
      type: filter.types.setGroupSize,
      payload: groupSize,
    });
  },
  setReservationType: (reservationType) => async (dispatch) => {
    dispatch({
      type: filter.types.setReservationType,
      payload: reservationType,
    });
  },
  setStartDate: (startDate) => async (dispatch) => {
    dispatch({
      type: filter.types.setStartDate,
      payload: startDate,
    });
  },
  selectTimeSlot: (timeSlot) => async (dispatch) => {
    dispatch({
      type: filter.types.selectTimeSlot,
      payload: timeSlot,
    });
  },
  resetSelectedTimeSlot: () => async (dispatch) => {
    dispatch({
      type: filter.types.resetSelectedTimeSlot,
    });
  },
  resetStartDate: () => async (dispatch) => {
    dispatch({
      type: filter.types.resetStartDate,
    });
  },
  selectSpace: (space) => async (dispatch) => {
    dispatch({
      type: filter.types.selectSpace,
      payload: space,
    });
  },
  resetFilterValues: () => async (dispatch) => {
    dispatch({
      type: filter.types.resetFilterValues,
    });
  },
  setUnavailableDaysStrings: (days) => async (dispatch) => {
    dispatch({
      type: filter.types.setUnavailableDaysStrings,
      payload: days,
    });
  }
}) as FilterActions;
