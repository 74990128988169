import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useReduxKey from '../../hooks/useReduxKey';

import Price from '../../components/Price/Price';
import SpaceHeader from '../../components/SpaceHeader';
import SpaceStickyFooter from '../../components/SpaceStickyFooter';

import './SpaceCardDetail.scss';
import { FilterState } from '../../reducers/filter';

const Space = () => {
  const {
    showPayments, showLocation,
    showPriceInclVat, showSelectedResourceStep,
  } = useReduxKey('configuration');

  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    if (!showSelectedResourceStep) {
      navigate('/services');
    }
  }, [showSelectedResourceStep]);

  function handleOnClickBack() {
    navigate(-1);
  }

  const { selectedSpace: space } = useReduxKey<FilterState>('filterState');

  return (
    <>
      <SpaceHeader onClick={handleOnClickBack} />

      <div className="card-detail-container">
        <div className="card-detail">
          <div className="close-button" onClick={() => handleOnClickBack()} />
          <div className="card-title">
            <h2>{space.name}</h2>
            {showLocation && <h3>{space.context}</h3>}
          </div>

          {space.image && (
            <img
              src={space.image}
              alt={space.name}
              className="img-responsive img-fit-contain"
            />
          )}

          <span className="capacity">
            {space.max_capacity !== null && (
              <span>
                {space.max_capacity} {t('people')}
              </span>
            )}
            {showPayments && (
              <>
                <span className="price">
                  <Price value={showPriceInclVat ? space.base_price_incl : space.base_price} />
                </span>
              </>
            )}
          </span>
          <p>{space.description}</p>

          <div className="extra-info">
            {space.features && (
              <div className="card-detail-features">
                <h3>{t('features')}</h3>
                <div dangerouslySetInnerHTML={{ __html: space.features }} />
              </div>
            )}
            {space.keep_in_mind && (
              <div className="card-detail-keepinmind">
                <h3>{t('keep_in_mind')}</h3>
                <div dangerouslySetInnerHTML={{ __html: space.keep_in_mind }} />
              </div>
            )}
          </div>
        </div>
      </div>

      <SpaceStickyFooter navigate={navigate} next="/services" />
    </>
  );
};

export default Space;
