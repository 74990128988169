import { ReactElement } from 'react';

import ServiceCard from '../ServiceCard';
import { Service } from '../../reducers/services';


interface ServiceListProps {
  title: string;
  services: Service[]
}

export default ({ title, services }: ServiceListProps): ReactElement => (
  <div className="bottom-spacing">
    <h3>{title}</h3>
    {services.map((service, idx) => (
      <ServiceCard
        key={service.id}
        service={service}
        serviceIdx={idx}
      />
    ))}
  </div>
);
