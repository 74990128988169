import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import serviceActions from '../../actions/serviceActions';

import SpaceHeader from '../../components/SpaceHeader';
import Card from './Card';

import './styles.scss';
import useReduxKey from '../../hooks/useReduxKey';
import filterActions from '../../actions/filterActions';
import useAnalytics from '../../hooks/useAnalytics';
import { TrackingEventEnum } from '../../utils/analytics';
import { FilterState } from '../../reducers/filter';

export default (): JSX.Element => {
  const navigate = useNavigate();

  const { resourceMap } = useReduxKey('resources');
  const { autoSelectBookableResource } = useReduxKey('configuration');
  const { trackEvent } = useAnalytics();
  const { resetServices } = serviceActions();

  const { selectedTimeSlot: timeslot } = useReduxKey<FilterState>('filterState');
  const { selectSpace } = filterActions();

  const spaces = useMemo(() => (
    timeslot.spaces.map(({ resource_id: resourceId }) => {
      const space = resourceMap[resourceId];
      return {
        ...space,
        select: (replace = false) => {
          resetServices();
          selectSpace(space);
          trackEvent(TrackingEventEnum.SCHEDULE_SPACE_SELECTED, { space });
          const _replace = replace;
          navigate(`/space/${space.id}`, { replace: _replace });
        },
      };
    })
  ), [timeslot]);

  useEffect(() => {
    if (spaces.length === 1) {
      spaces[0].select(true);
    } else if (autoSelectBookableResource) {
      const randomIndex = Math.floor(spaces.length * Math.random()) % spaces.length;
      spaces[randomIndex].select(true);
    } else {
      trackEvent(TrackingEventEnum.SCHEDULE_SPACE);
    }
  }, []);

  function onClickBack() {
    navigate(-1);
  }

  return (
    <>
      <SpaceHeader
        onClick={onClickBack}
      />
      <div className="container-space-selection">
        {spaces.map((space) => (
          <div className="card-wrapper" key={space.id}>
            <Card space={space} onClick={() => space.select()} />
          </div>
        ))}
      </div>
    </>
  );
};
