import createReducer from '../utils/createReducer';

export interface Attendees {
  form: Record<string, unknown>;
}

const INITAL_STATE: Attendees = {
  form: {},
};

export default createReducer('attendees', INITAL_STATE, {
  setAttendeesForm: (state, payload) => ({ ...state, form: payload }),
  resetAttendees: () => INITAL_STATE,
});
