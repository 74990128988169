type CacheEntry<T> = {
  expire: number;
  value: T;
}

/**
 * Number: milliseconds to live for
 * Date: expiry datetime
 * null: infinite
 */
export type CacheExpiry = number | Date | null;

function convertExpiry(expiry: CacheExpiry) : number {
  if (expiry === null) {
    return Infinity;
  }
  if (typeof expiry === 'number') {
    return expiry + (new Date().getTime());
  }
  return expiry.getTime();
}

export default class AsyncCache<T> {
  map: Map<string, CacheEntry<T>>;

  constructor() {
    this.map = new Map<string, CacheEntry<T>>();
  }

  get(key: string, defaultValue: T = null) : T|null {
    const entry = this.map.get(key);
    if (entry) {
      if ((new Date()).getTime() < entry.expire) {
        return entry.value;
      }
      console.log('Too bad, your entry expired...', entry);
      // Remove expired key to save memory
      this.map.delete(key);
    }
    return defaultValue;
  }

  set(key: string, value: T, expiry : CacheExpiry) : void {
    const entry: CacheEntry<T> = {
      expire: convertExpiry(expiry),
      value,
    };
    this.map.set(key, entry);
  }

  /**
   *
   * @param key string
   * @param expiry milliseconds, date or null
   * @param generator function that generates the value to be remembered
   */
  async remember(key: string, expiry : CacheExpiry, generator: () => Promise<T>) : Promise<T> {
    const object = this.get(key);
    if (object !== null) {
      return object;
    }
    const newObject = await generator();
    this.set(key, newObject, expiry);
    return newObject;
  }
}
