import './Price.scss';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { Nullable } from '../../types';
import useReduxKey from '../../hooks/useReduxKey';

interface PriceProps {
  value: Nullable<number | string>;
}

const Price = ({ value }: PriceProps) => {
  const { currency } = useReduxKey('configuration');
  const { i18n } = useTranslation();

  const formatter = useMemo(() => new Intl.NumberFormat(i18n.language, {
    currency,
    style: 'currency',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  }), [currency, i18n.language]);

  const displayedValue = typeof value === 'string'
    ? parseFloat(value.replace(',', '.'))
    : value;

  return (
    <>
      {value !== null && value !== undefined && (
        <span className="price-container">
          {formatter.format(displayedValue)}
        </span>
      )}
    </>
  );
};

export default Price;
