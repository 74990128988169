import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Button from '../Button/Button';

interface ErrorFooterProps {
  // eslint-disable-next-line react/require-default-props
  actionCallback?: () => void
}

function ErrorFooter({ actionCallback }: ErrorFooterProps): JSX.Element {
  const navigate = useNavigate();
  const { t } = useTranslation();

  function handleOnClick() {
    if (!actionCallback) {
      navigate('/');
    } else {
      actionCallback();
    }
  }

  return (
    <div className="sticky-footer">
      <Button
        onClick={() => handleOnClick()}
        className="button--grey button--border button--large"
        label={t('try_again')}
      />
      <p className="foobar">{t('try_again_long')}</p>
    </div>
  );
}

export default ErrorFooter;
