import React, { useMemo, useState } from 'react';
import * as dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useReduxKey from '../../hooks/useReduxKey';
import CompanyHeader from '../../components/CompanyHeader/CompanyHeader';
import resourceActions from '../../actions/resourceActions';
import useAsyncEffect from '../../hooks/useAsyncEffect';
import ReservationCard from '../../components/ReservationCard';
import './style.scss';
import GenericStickyFooter from '../../components/GenericStickyFooter';
import Button from '../../components/Button/Button';
import reservationActions from '../../actions/reservationActions';
import Icon from '../../images/confirmation-black.svg';

const ReservationDetail = () => {
  const reservation = useReduxKey('reservationDetail');
  const { resourceMap } = useReduxKey('resources');
  const { userReservationTypes } = useReduxKey('configuration');
  const { fetchResources } = resourceActions();
  const { cancelReservation } = reservationActions();
  const [confirming, setConfirming] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();

  useAsyncEffect(async () => {
    await fetchResources();
  }, []);

  const space = useMemo(
    () => reservation && resourceMap[reservation.resource_id],
    [resourceMap, reservation],
  );
  const selectedTimeSlot = useMemo(
    () =>
      reservation && {
        dStart: dayjs.utc(reservation.start),
        dEnd: dayjs.utc(reservation.end),
      },
    [reservation],
  );
  const reservationType = useMemo(
    () =>
      reservation && userReservationTypes
        && userReservationTypes.find((type) => type.enabled) // Only if user can choose
        && userReservationTypes.find(
          (type) => type.reservation_type === reservation.reservation_type,
        ),
    [reservation, userReservationTypes],
  );

  return space && reservation ? (
    <>
      <CompanyHeader />
      <div className="container-reservation-detail">
        {reservation.status === 'cancelled' && (
          <>
            <h2>
              {t('reservation_cancelled_text')}
              <img
                src={Icon}
                title="check"
                alt="check"
                className="confirmation-icon"
              />
            </h2>
            <p>{t('cancelled_confirmation_email')}</p>
          </>
        )}
        <ReservationCard
          selectedTimeSlot={selectedTimeSlot}
          reservation={reservation}
          reservationType={reservationType?.name}
          space={space}
        />
      </div>
      <GenericStickyFooter className="cancellation-footer">
        {(() => {
          if (reservation.status === 'cancelled') {
            return (
              <Button
                label={t('make_new_reservation')}
                className="button--primary button--large button--secondary"
                onClick={() => navigate('/')}
              />
            );
          }
          if (confirming) {
            return (
              <>
                <p>{t('cancellation_confirm_text')}</p>
                <div className="buttons">
                  <Button
                    label={t('undo')}
                    className="button--grey button--border button--large button--secondary"
                    onClick={() => setConfirming(!confirming)}
                  />
                  <Button
                    label={t('confirm')}
                    className="button--primary button--large button--secondary"
                    onClick={() => cancelReservation(reservation.id)}
                  />
                </div>
              </>
            );
          }
          return (
            <Button
              label={t('cancel_reservation')}
              className="button--primary button--large button--secondary"
              onClick={() => setConfirming(true)}
            />
          );
        })()}
      </GenericStickyFooter>
    </>
  ) : null;
};

export default ReservationDetail;
