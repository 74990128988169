import createReducer from '../utils/createReducer';

const INITIAL_STATE = {
  interactedWithTheWidget: false,
};

export default createReducer('user', INITIAL_STATE, {
  setUserInteractedWithTheWidget: (state, payload) => ({
    ...state,
    interactedWithTheWidget: payload,
  }),
});
