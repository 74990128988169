import createReducer from '../utils/createReducer';
import { Nullable } from '../types';
import { ResourceTreeNode } from '../hooks/useLocations';
import { Space } from './filter';

export interface Resources {
  resourceMap: Nullable<{ [id: string]: Space }>,
  resourceTree: Nullable<ResourceTreeNode[]>,
}

const INITIAL_STATE: Resources = {
  resourceTree: null,
  resourceMap: {},
};

export default createReducer('resources', INITIAL_STATE, {
  setResourceTree: (state, payload) => ({
    ...state,
    resourceTree: payload,
  }),
  setResourceMap: (state, payload) => ({
    ...state,
    resourceMap: payload,
  }),
});
