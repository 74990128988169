import useActions from '../hooks/useActions';
import pricing from '../reducers/pricing';

export default () => useActions({
  setPriceCalculationProcessed: (isCustomPricingLoaded) => async (dispatch) => {
    dispatch({
      type: pricing.types.setPriceCalculationProcessed,
      payload: isCustomPricingLoaded,
    })
  },
});