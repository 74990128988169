import { useTranslation } from 'react-i18next';

import Header from '../ConfirmHeader/index';
import Detail from '../ErrorDetail/index';
import Footer from '../ErrorFooter/index';

interface FooterProps {
  processName: string;
  actionCallback?: () => void;
}

export default ({ processName, actionCallback }: FooterProps) => {
  const { t } = useTranslation();
  const headerLabel = processName === 'booking' ? t('booking_failed') : t('payment_failed');

  return (
    <>
      <Header title={headerLabel} />
      <Detail processName={processName} />
      <Footer actionCallback={actionCallback} />
    </>
  );
};
