import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Button from '../Button/Button';

import './styles.scss';
import GenericStickyFooter from '../GenericStickyFooter';

export default ({ showDetailsOnStart }) => {
  const { configuration, filterState } = useSelector((state) => state);
  const { t } = useTranslation();

  const navigate = useNavigate();
  function handleOnClickAvailableSpaces() {
    navigate('/spaces');
  }

  const spacesCount = useMemo(
    () =>
      (filterState.selectedTimeSlot !== null
        ? filterState.selectedTimeSlot.spaces.length
        : 0),
    [filterState.selectedTimeSlot],
  );

  return (
    <GenericStickyFooter
      className={
        showDetailsOnStart
          ? 'filter-sticky-footer'
          : 'filter-sticky-footer hide-text'
      }
    >
      <Button
        disabled={spacesCount === 0}
        onClick={() => handleOnClickAvailableSpaces()}
        label={`${t('available_spaces')} (${spacesCount})`}
        className={`button button--large ${
          spacesCount === 0 ? 'button--white' : 'button--secondary'
        }`}
      />
      <p className="thank-you-text">
        {t('thank_you', { name: configuration.name })}
      </p>
    </GenericStickyFooter>
  );
};
