import createReducer from '../utils/createReducer';
import { Nullable } from '../types';

export interface Service {
  // eslint-disable-next-line camelcase
  included_by_default: boolean;
  selectedQuantity: number;
  quantitative: boolean;
  description: string;
  // eslint-disable-next-line camelcase
  image_url: string;
  capacity: number;
  price: number;
  name: string;
  id: string;
  priceIncl: number;
  vatRate: number;
}

export interface Services {
  includedServices: Nullable<Service[]>;
  additionalServices: Nullable<Service[]>;
}

const INITIAL_STATE = {
  includedServices: null,
  additionalServices: null,
};

export default createReducer('services', INITIAL_STATE, {
  resetServices: (state, payload) => ({
    ...state,
    includedServices: payload,
    additionalServices: payload,
  }),
  setIncludedServices: (state, payload) => ({
    ...state,
    includedServices: payload,
  }),
  setAdditionalServices: (state, payload) => ({
    ...state,
    additionalServices: payload,
  }),
  increaseAdditionalServiceQuantity: (state, payload) => ({
    ...state,
    additionalServices: [
      ...state.additionalServices.slice(0, payload),
      {
        ...state.additionalServices[payload],
        selectedQuantity: state.additionalServices[payload].selectedQuantity + 1,
      },
      ...state.additionalServices.slice(payload + 1),
    ],
  }),
  decreaseAdditionalServiceQuantity: (state, payload) => ({
    ...state,
    additionalServices: [
      ...state.additionalServices.slice(0, payload),
      {
        ...state.additionalServices[payload],
        selectedQuantity: state.additionalServices[payload].selectedQuantity - 1,
      },
      ...state.additionalServices.slice(payload + 1),
    ],
  }),
});
