import useActions from '../hooks/useActions';
import services from '../reducers/services';

export default () => useActions({
  resetServices: () => (dispatch) => {
    dispatch({
      type: services.types.resetServices,
      payload: null,
    });
  },
  setIncludedServices: (includedServices) => async (dispatch) => {
    dispatch({
      type: services.types.setIncludedServices,
      payload: includedServices,
    });
  },
  setAdditionalServices: (additionalServices) => async (dispatch) => {
    dispatch({
      type: services.types.setAdditionalServices,
      payload: additionalServices,
    });
  },
  increaseAdditionalServiceQuantity: (serviceIdx) => (dispatch) => {
    dispatch({
      type: services.types.increaseAdditionalServiceQuantity,
      payload: serviceIdx,
    });
  },
  decreaseAdditionalServiceQuantity: (serviceIdx) => (dispatch) => {
    dispatch({
      type: services.types.decreaseAdditionalServiceQuantity,
      payload: serviceIdx,
    });
  },
});
