import createReducer from '../utils/createReducer';

export type CustomField = {
  type: string;
  required: boolean;
  hidden: boolean;
  // eslint-disable-next-line camelcase
  salesforce_field_name: string;
}

export interface CustomFieldsState {
  customFields: null | CustomField[];
  customFieldsPending: boolean;
  error: null | string;
}

const INITIAL_STATE: CustomFieldsState = {
  customFields: null,
  customFieldsPending: true,
  error: null,
};

export default createReducer('customFieldsState', INITIAL_STATE, {
  fetchCustomFieldsPending: (state) => ({ ...state, customFieldsPending: true }),
  fetchCustomFieldsSuccess: (state, customFields) => ({
    ...state,
    customFieldsPending: false,
    customFields,
  }),
  fetchCustomFieldsError: (state, error: string | null) => ({
    ...state,
    customFields: null,
    customFieldsPending: false,
    error,
  }),
});
