import { Reservation } from '../types/Schemas';

const getCapacityBasedReservationData = (filterState) => {
  const { selectedTimeSlot, selectedSpace } = filterState;
  const linkedSpace = selectedTimeSlot.spaces.find(
    (space) => space.resource_id === selectedSpace.id,
  );

  return {
    reservation_id: linkedSpace
      ? linkedSpace.booking_id
      : null,
  };
};

const getAvailabilityBasedReservationData = (filterState) => {
  const {
    selectedTimeSlot: timeslot, selectedSpace, reservationType,
  } = filterState;
  return {
    start_date: timeslot.dStart.toISOString(),
    end_date: timeslot.dEnd.toISOString(),
    resource_id: selectedSpace.id,
    resource_price: selectedSpace.base_price,
    reservation_type: reservationType,
  };
};

const getReservationContactData = (contactDetails, contactId, leadId, accountId) => {
  const { notes, form: {
    firstName, lastName, email,
    company, attendees = [], ...customFields
  } } = contactDetails;

  return {
    first_name: firstName,
    last_name: lastName,
    company: company || undefined,
    custom_fields: customFields,
    notes: notes || undefined,
    contact_id: contactId,
    lead_id: leadId,
	account_id: accountId,
    email,
    attendees,
  };
};

const getReservationData = (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  filterState, contactId, leadId, accountId, contactDetails, attendeeDetails,
): Omit<Reservation, 'services'> => {
  const { selectedTimeSlot, groupSize } = filterState;
  const { model } = selectedTimeSlot;

  const reservationContactData = getReservationContactData(
    contactDetails, contactId, leadId, accountId
  );
  const attendees = attendeeDetails.form.attendees as Record<string, unknown>[];
  const reservationData = model === 'availability'
    ? getAvailabilityBasedReservationData(filterState)
    : getCapacityBasedReservationData(filterState);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return {
    ...reservationData,
    quantity: groupSize,
    ...reservationContactData,
    attendees,
  };
};

export default getReservationData;
