import React, { ReactElement, useCallback, useContext, useEffect, useRef, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';

import classNames from 'classnames';
import { TrackingEventEnum } from '../../utils/analytics';
import filterActions from '../../actions/filterActions';

import AccordionButton, { AccordionButtonRef } from '../AccordionButton/AccordionButton';
import TimeIcon from '../../images/time.svg';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import Button from '../Button/Button';
import DateTime, { formatDateTime } from '../DateTime';

import './style.scss';
import useReduxKey from '../../hooks/useReduxKey';
import { CalendarContext } from '../../contexts/CalendarProvider';
import useAnalytics from '../../hooks/useAnalytics';
import { FilterState, Timeslot } from '../../reducers/filter';

const TimeslotGroup = ({
  title,
  selectedTimeSlot,
  timeslots,
  handleOnClickTimeSlot,
  localeObjects,
  locale,
}) =>
  timeslots.length > 0 && (
    <div className="container-available-time-slots">
      <h4>{title}</h4>
      <div className="align-right">
        <ul className="available-time-slots">
          {timeslots.map((timeSlot) => (
            <li
              key={`${timeSlot.dStart.toISOString()}-${timeSlot.dEnd.toISOString()}`}
            >
              <Button
                onClick={() => handleOnClickTimeSlot(timeSlot)}
                label={(
                  <span><DateTime
                    datetime={timeSlot.dStart}
                    format="time"
                    localeObjects={localeObjects}
                    locale={locale}
                    displayTimezone={timeSlot.timezone}
                  /> - <DateTime
                    datetime={timeSlot.dEnd}
                    format="time"
                    localeObjects={localeObjects}
                    locale={locale}
                    displayTimezone={timeSlot.timezone}
                  />
                  </span>
                )}
                className={
                  selectedTimeSlot != null
                  && selectedTimeSlot.dStart === timeSlot.dStart
                  && selectedTimeSlot.dEnd === timeSlot.dEnd
                    ? 'button button--small button--secondary full-width button--small'
                    : 'button button--small button--white full-width button--small'
                }
              />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );

const Timeslots = ({
  timeslots: {
    timeslots,
    fetchingSlots,
  },
  selectedTimeslot,
  handleOnClickTimeSlot,
  fetching = false,
}): ReactElement => {
  const { t, i18n } = useTranslation();
  const { localeObjects } = useReduxKey('configuration');

  if (fetchingSlots || fetching) {
    return <LoadingSpinner />;
  }

  if (Array.from(timeslots.values())
    .every((arr: unknown[]) => arr.length === 0)) {
    return (
      <p className="no-slots-available">{t('no_available_time_slots')}</p>
    );
  }

  return (
    <>
      {Array.from(timeslots.entries())
        .map(([group, slots]) => (
          <TimeslotGroup
            key={group}
            title={t(group)}
            timeslots={slots}
            selectedTimeSlot={selectedTimeslot}
            handleOnClickTimeSlot={handleOnClickTimeSlot}
            localeObjects={localeObjects}
            locale={i18n.language}
          />
        ))}
    </>
  );
};

interface TimeslotPickerProps {
  timeslots: {
    timeslots: Map<string, Timeslot[]>,
    fetchingSlots: boolean,
  },
  disabled: boolean,
  showDetailsOnStart: boolean,
}

export default ({
  timeslots,
  disabled,
  showDetailsOnStart,
}: TimeslotPickerProps): JSX.Element => {
  const [nextDate, setNextDate] = useState(null);
  const [previousDate, setPreviousDate] = useState(null);

  const configuration = useReduxKey('configuration');
  const { trackEvent } = useAnalytics();
  const {
    days,
    selectedDay,
    isFetchingFor,
  } = useContext(CalendarContext);

  const inCalendarRange = useCallback((date: Dayjs): boolean => {
    let valid = date.isSameOrAfter(new Date(), 'day');

    if (configuration.calendarStartDate) {
      valid = valid && date.isSameOrAfter(dayjs(configuration.calendarStartDate, 'YYYY-MM-DD'), 'day');
    }

    if (configuration.calendarEndDate) {
      valid = valid && date.isSameOrBefore(dayjs(configuration.calendarEndDate, 'YYYY-MM-DD'), 'day');
    }

    return valid;
  }, [configuration]);

  const {
    setStartDate,
    selectTimeSlot,
  } = filterActions();

  const buttonRef = useRef<AccordionButtonRef>();
  const { t, i18n } = useTranslation();
  const {
    selectedTimeSlot,
    startDate,
    unavailableDaysStrings,
  } = useReduxKey<FilterState>('filterState');

  useEffect(() => {
    buttonRef.current.setOpen(showDetailsOnStart);
    if (showDetailsOnStart) {
      trackEvent(TrackingEventEnum.SCHEDULE_TIME);
    }
  }, [showDetailsOnStart]);

  const previousEnabledWeekDay = useCallback((date: Dayjs): Dayjs | null => {
    const prev = date.subtract(1, 'day');

    if (unavailableDaysStrings.includes(prev.format('YYYY-MM-DD'))) {
      return previousEnabledWeekDay(prev);
    }

    return prev;
  }, [days]);

  const nextEnabledWeekDay = useCallback((date: Dayjs): Dayjs | null => {
    const next = date.add(1, 'day')

    if (unavailableDaysStrings.includes(next.format('YYYY-MM-DD'))) {
      return nextEnabledWeekDay(next);
    }

    return next;
  }, [days]);

  function handleOnClickTimeSlot(timeSlot: Timeslot) {
    selectTimeSlot(timeSlot);
    trackEvent(TrackingEventEnum.SCHEDULE_TIME_SELECTED, {
      start: timeSlot.dStart.toISOString(),
      end: timeSlot.dEnd.toISOString(),
    });
  }

  useEffect(() => {
    if (startDate === null) {
      return;
    }

    const dStartDate = dayjs(startDate, 'YYYY-MM-DD');

    const newNextDate = nextEnabledWeekDay(dStartDate);
    const newPreviousDate = previousEnabledWeekDay(dStartDate);

    setNextDate(newNextDate !== null && inCalendarRange(newNextDate) ? newNextDate.format('YYYY-MM-DD') : null);
    setPreviousDate(newPreviousDate !== null && inCalendarRange(newPreviousDate) ? newPreviousDate.format('YYYY-MM-DD') : null);
  }, [startDate]);

  return (
    <div className="time-container">
      <AccordionButton
        ref={buttonRef}
        label={
          !selectedTimeSlot
            ? t('what_time')
            : (
              <span><DateTime
                datetime={selectedTimeSlot.dStart}
                format="time"
                localeObjects={configuration.localeObjects}
                locale={i18n.language}
                displayTimezone={selectedTimeSlot.timezone}
              /> - <DateTime
                datetime={selectedTimeSlot.dEnd}
                format="time"
                localeObjects={configuration.localeObjects}
                locale={i18n.language}
                displayTimezone={selectedTimeSlot.timezone}
              />
              </span>
            )
        }
        disabled={disabled}
        icon={TimeIcon}
      >
        <div className="date-toggle-container">
          <button
            type="button"
            aria-label="Previous day"
            onClick={() => setStartDate(previousDate)}
            disabled={previousDate === null}
            className={classNames('date-toggle-button', 'previous', {
              disabled: previousDate === null,
              'not-disabled': previousDate !== null,
            })}
          />
          <span className="current-date">
            {startDate && (
              `${formatDateTime(dayjs(selectedDay), 'date-long', configuration.localeObjects, i18n.language)}`
            )}
          </span>
          <button
            type="button"
            aria-label="Next day"
            onClick={() => setStartDate(nextDate)}
            disabled={nextDate === null}
            className={classNames('date-toggle-button', 'next', {
              disabled: nextDate === null,
              'not-disabled': nextDate !== null,
            })}
          />
        </div>
        <Timeslots
          fetching={selectedDay !== undefined ? isFetchingFor(selectedDay) : false}
          timeslots={timeslots}
          selectedTimeslot={selectedTimeSlot}
          handleOnClickTimeSlot={handleOnClickTimeSlot}
        />

        <p className="no-suitable-timeslots">
          {t('no_suitable_timeslot', { email: t('mailto_email_address') })}
        </p>
      </AccordionButton>
    </div>
  );
};
