import useActions from '../hooks/useActions';
import useBooker25 from '../hooks/useBooker25';
import reservationDetail from '../reducers/reservationDetail';
import useAnalytics from '../hooks/useAnalytics';
import { TrackingEventEnum } from '../utils/analytics';

export default () => {
  const api = useBooker25();
  const { trackEvent } = useAnalytics();

  return useActions({
    fetchReservation: (reservationId) => async (dispatch) => {
      dispatch({
        type: reservationDetail.types.set,
        payload: await api.getReservation(reservationId),
      });
    },

    cancelReservation: (reservationId) => async (dispatch) => {
      trackEvent(TrackingEventEnum.SCHEDULE_CANCEL_RESERVATION_SUBMITTED, {
        reservation: reservationId,
      });
      await api.cancelReservation(reservationId);
      trackEvent(TrackingEventEnum.SCHEDULE_CANCEL_RESERVATION_SUCCEEDED, {
        reservation: reservationId,
      });
      dispatch({
        type: reservationDetail.types.update,
        payload: {
          status: 'cancelled',
        },
      });
    },
  });
};
