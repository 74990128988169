import { useEffect, useMemo, ReactElement, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { renderToString } from 'react-dom/server';
import { useTranslation } from 'react-i18next';

import CheckoutHeader from './CheckoutHeader';
import SpaceStickyFooter from '../../components/SpaceStickyFooter';
import BookingPending from '../../components/ProcessPending/index';

import ReservationCard from '../../components/ReservationCard';
import { TrackingEventEnum } from '../../utils/analytics';
import useAnalytics from '../../hooks/useAnalytics';
import useReduxKey from '../../hooks/useReduxKey';

import './Overview.scss';
import { FilterState } from '../../reducers/filter';
import { Pricing } from '../../reducers/pricing';
import PaymentRedirect from '../../components/PaymentRedirect';
import generatePaymentRedirectBlob from '../../utils/paymentRedirectBlob';

export default function OverviewPage(): ReactElement {
  const navigate = useNavigate();
  const {
    reservationType, selectedTimeSlot,
    groupSize, selectedSpace,
  } = useReduxKey<FilterState>('filterState');
  const { t } = useTranslation();

  const { userReservationTypes, paymentsEnabled, showPayments } = useReduxKey('configuration');
  const { priceCalculationProcessed } = useReduxKey<Pricing>('pricing');
  const { trackEvent } = useAnalytics();
  const { state } = useLocation();
  const footerButtenLabel = paymentsEnabled ? 'pay' : 'submit';

  const paymentWindow = useRef<Window | null>(null);

  const openPaymentWindow = () => {
    if (paymentWindow.current) {
      paymentWindow.current.close();
    }
    const paymentRedirectAsHtml = renderToString(<PaymentRedirect />);
    const paymentWindowURL = URL.createObjectURL(
      new Blob([generatePaymentRedirectBlob(paymentRedirectAsHtml)], { type: "text/html" })
    );
    // We open the payment window in a new tab at the user's click to avoid popup blockers, we will set the URL later
    paymentWindow.current = window.open(paymentWindowURL, 'paymentWindow');
  }

  useEffect(() => {
    trackEvent(TrackingEventEnum.SCHEDULE_OVERVIEW);
  }, [trackEvent]);

  const selectedReservationTypeName = useMemo(() => {
    const userType = userReservationTypes.find((type) =>
      type.reservation_type === reservationType);
    return userType?.name;
  }, [userReservationTypes, reservationType]);

  if (selectedSpace === null) {
    return <span>{t('coming_up')}</span>;
  }

  const shouldDisplayPage = !showPayments || (showPayments && priceCalculationProcessed);

  return shouldDisplayPage ? (
    <>
      <CheckoutHeader step={state.step} />

      <div className="container-checkout-overview">
        <ReservationCard
          space={selectedSpace}
          reservation={{ quantity: groupSize }}
          selectedTimeSlot={selectedTimeSlot}
          reservationType={selectedReservationTypeName}
        />
      </div>
      {paymentsEnabled ? (
        <SpaceStickyFooter label={t(footerButtenLabel)} navigate={navigate} actionCallback={openPaymentWindow} paymentWindowRef={paymentWindow} next="/bookingProgress" nextStep={state.step + 1}/>
      ) : (
        <SpaceStickyFooter label={t(footerButtenLabel)} navigate={navigate} next="/bookingProgress" nextStep={state.step + 1}/>
      )}
    </>
  ) : (
    <BookingPending processName="booking" />
  );
}