import React, { ReactElement } from 'react';

import './styles.scss';

interface QuantityButtonProps {
  quantity: number;
  quantityLimit: number;
  increaseQuantityCallback: () => void;
  decreaseQuantityCallback: () => void;
}

export default (props: QuantityButtonProps): ReactElement => {
  const {
    quantity, quantityLimit,
    increaseQuantityCallback,
    decreaseQuantityCallback,
  } = props;

  const handleQuantityIncrease = () => {
    if (quantity + 1 > quantityLimit) {
      return;
    }
    increaseQuantityCallback();
  };

  const handleQuantityDecrease = () => {
    if (quantity - 1 < 0) {
      return;
    }
    decreaseQuantityCallback();
  };

  return (
    <div className="button-container">
      <button onClick={handleQuantityDecrease}>-</button>
      <div className="quantity">
        <h4>{quantity}</h4>
      </div>
      <button onClick={handleQuantityIncrease}>+</button>
    </div>
  );
};
