import { Reducer } from 'redux';

type Handler<T> = ((currentState?: T, ...args) => T)

interface TypesReducer<T, H extends Record<string, any>> extends Reducer<T> {
  types: Record<keyof H | 'reset', string>
}

export default function createReducer<T, H = Record<string, Handler<T>>>(prefix: string, initialState: T, actionHandlers: H): TypesReducer<T, H> {
  const identifier = (key) => `${prefix}_${key}`;

  const handlers: Record<string, Handler<T>> = {
    [identifier('reset')]: () => initialState,
  };
  const types = {
    reset: identifier('reset'),
  };

  Object
    .entries(actionHandlers)
    .forEach(([key, handler]) => {
      types[key] = identifier(key);
      handlers[identifier(key)] = handler;
    });

  const reducer: TypesReducer<T, H> = (state: T, { type, payload }: { type: string, payload: unknown }) => {
    const currentState = state || initialState;
    const handler = handlers[type];
    return handler !== undefined
      ? handler(currentState, payload)
      : currentState;
  };

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  reducer.types = types;

  return reducer;
}
