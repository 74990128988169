import useActions from '../hooks/useActions';
import details from '../reducers/details';

export default () => useActions({
  setDetails: (formData) => async (dispatch) => {
    dispatch({
      type: details.types.setForm,
      payload: formData,
    });
  },

  setNotes: (notes) => async (dispatch) => {
    dispatch({
      type: details.types.setNotes,
      payload: notes,
    });
  },

  reset: () => async (dispatch) => {
    dispatch({
      type: details.types.reset,
    });
  },
});
