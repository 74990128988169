import useBooker25 from '../hooks/useBooker25';
import useActions from '../hooks/useActions';
import calendar, { Days } from '../reducers/calendar';
import { CalendarAvailabilityResponse, TimeslotContent } from '../services/Booker25';
import { mapTimeslot } from './timeslotActions';
import { ResourceTreeNode } from '../hooks/useLocations';

function calculateDayAvailability(timeslots: TimeslotContent[] | null, selectedLocation: ResourceTreeNode, showGroupSizeStep: boolean, selectedGroupSize: number): boolean {
  // Timeslots are not given when getting the calendar availability.
  // We assume there is availability.
  if (timeslots === null) {
    return true
  }

  return timeslots
    .map(mapTimeslot(
      selectedLocation,
      showGroupSizeStep,
      selectedGroupSize
    ))
    .some(ts => ts.available)
}

function calculateCalendarAvailability(response: CalendarAvailabilityResponse, selectedLocation: ResourceTreeNode, showGroupSizeStep: boolean, selectedGroupSize: number): Days {
  return response.days.reduce((output, { date, available , timeslots }) => {
    return ({
      ...output,
      [date]: {
        available: available && calculateDayAvailability(timeslots, selectedLocation, showGroupSizeStep, selectedGroupSize)
      },
    })
  }, {});
}

export interface CalendarActions {
  loadCalendarAvailability: (
    start: string,
    end: string,
    timezone: string,
    resources: string[],
    reservationType: string | undefined,
    selectedGroupSize: number,
    selectedLocation: ResourceTreeNode,
    showGroupSizeStep: boolean,
  ) => Promise<Days>,
  setMonth: (date: Date) => void,
  setSelectedDay: (date: Date) => void,
}

export default function calendarActions(): CalendarActions {
  const api = useBooker25();

  return useActions<CalendarActions>({
    loadCalendarAvailability: (
      start: string,
      end: string,
      timezone: string,
      resources: string[],
      reservationType: string | undefined,
      selectedGroupSize: number,
      selectedLocation: ResourceTreeNode,
      showGroupSizeStep: boolean,
    ) => async (dispatch): Promise<Days> => {
      dispatch({
        type: calendar.types.setFetching,
        payload: true,
      });

      try {
        const response = await api.calendarAvailability(start, end, timezone, resources, reservationType, selectedGroupSize);
        const payload = calculateCalendarAvailability(response, selectedLocation, showGroupSizeStep, selectedGroupSize);

        dispatch({
          type: calendar.types.setCalendarDays,
          payload,
        });
        return payload;
      } catch (e) {
        console.error(e)
      } finally {
        dispatch({
          type: calendar.types.setFetching,
          payload: false,
        });
      }
    },
    setMonth: (date: Date) => async (dispatch) => {
      dispatch({
        type: calendar.types.setMonth,
        payload: date,
      });
    },
    setSelectedDay: (date: Date) => async (dispatch) => {
      dispatch({
        type: calendar.types.setSelectedDay,
        payload: date,
      });
    },
  });
}
