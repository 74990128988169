import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import './CompanyHeader.scss';
import createPostMessage, { postMessageTypes } from '../../postMessageActions';
import useReduxKey from '../../hooks/useReduxKey';
import { ReactComponent as CloseIcon } from '../../images/close.svg';
import { LanguageContext } from '../../contexts/LanguageProvider';

const LangButton = ({ lang, changeLanguage }) => {
  const { i18n } = useTranslation();

  return (
    <button
      type="button"
      className={i18n.language.slice(0, 2) === lang ? 'active' : null}
      onClick={() => changeLanguage(lang)}
    >
      {lang.toUpperCase()}
    </button>
  );
};

export default () => {
  const { language, languages, setLanguage } = useContext(LanguageContext);

  const { name, logo, parentOrigin, showCloseButton } = useReduxKey(
    'configuration',
  );

  function handleOnClickBack() {
    window.parent.postMessage(
      createPostMessage(postMessageTypes.toggle),
      parentOrigin,
    );
  }

  return (
    <div className="company-header">
      {showCloseButton ? (
        <div
          role="button"
          onClick={() => handleOnClickBack()}
          className="close-button"
        >
          <CloseIcon />
        </div>
      ) : (
        <div />
      )}

      {logo && (
        <img src={logo} title={name} alt={name} className="companyLogo" />
      )}

      <div className="header-spacer" />

      <div className="lang-select">
        {languages.length === 0 && null}

        {languages.length === 2 && (
          <>
            <LangButton lang={languages[0]} changeLanguage={setLanguage} />
            <div className="divider" />
            <LangButton lang={languages[1]} changeLanguage={setLanguage} />
          </>
        )}

        {languages.length > 2 && (
          <select
            name="languages"
            value={language.slice(0, 2)}
            onChange={(e) => setLanguage(e.currentTarget.value)}
          >
            {languages.map((lang) => (
              <option key={lang} value={lang}>
                {lang.toUpperCase()}
              </option>
            ))}
          </select>
        )}
      </div>
    </div>
  );
};
