/* eslint-disable camelcase */
import { Dayjs } from 'dayjs';
import genQueryParams from '../utils/genQueryParams';
import { PriceBreakdown, Reservation } from '../types';

export const environments = {
  dev: 'dev',
  acc: 'acc',
  staging: 'staging',
  prod: 'prod',
};


type Method = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';
interface ExtraCallParams {
  params?: unknown;
  body?: unknown;
  headers?: HeadersInit;
  signal?: AbortSignal;
}

interface Translations {
  widget: Record<string, Record<string, string>>
  objects: Record<string, Record<string, string>>
  landingpage: Record<string, Record<string, string>>
}

interface ResourceSettings {
  use_company_field: boolean;
  registrant_type: 'contact' | 'lead' | 'account';
}

interface WidgetSettings {
  enabled_calendar_days: string[];
  calendar_end_date: null | string;
  calendar_start_date: null | string;
  currency: string;
  show_services_step: boolean;
  show_selected_resource_step: boolean;
  auto_select_bookable_resource: boolean;
  show_location: boolean;
  show_price_incl_vat: boolean;
  show_notes: boolean;
  enable_payments: boolean;
  show_payments: boolean;
  show_terms_and_conditions_step: boolean;
  show_group_size_step: boolean;
  group_size_max: number;
  display_in_resource_timezone: boolean;

}

interface HeaderSettings {
  logo: string;
}

interface ReservationSettings {
  use_per_attendee_fields: boolean;
}

interface ReservationTypeSettings {
  name: string;
  enabled: boolean
  order: number;
}

export interface PageSettings {
  reservation_settings: ReservationSettings;
  per_attendee_fields: unknown;
  custom_fields: unknown;
  reservation_type_settings: Partial<ReservationTypeSettings>[];
  widget_settings: Partial<WidgetSettings>;
  header_settings: Partial<HeaderSettings>;
  reservation_model: 'availability' | 'capacity';
  widget_button: unknown;
  resource_settings: Partial<ResourceSettings>;
  max_days_in_advance: number | null
  first_day_of_week: string;
  default_language: string;
  enabled_languages: string[];
  fullscreen_background_color: string;
  primary_font_color: string;
  primary: string;
  translations: Partial<Translations>
}

interface Page {
  id: string
  slug: string
  name: string
  default: boolean
  url: string
  settings: Partial<PageSettings>
}

export interface TimeslotContent {
  start: string;
  end: string;
  available: boolean;
  resource: string;
}

export interface Timeslot {
  [key: string]: TimeslotContent,
}

interface ApiService {
  id: string;
  name: string;
  price: number;
  image_url: string;
  description: string;
  quantitative: boolean;
  included_by_default: boolean;
  price_incl: number;
  vat_rate: number;
}

export interface ServiceAvailability {
  remaining_capacity: number;
  service: ApiService;
}

export interface ServiceAvailabilityResponse {
  available_services: ServiceAvailability[];
}

export interface CalendarAvailabilityResponse {
  days: {
    date: string,
    available: boolean,
    timeslots: TimeslotContent[] | null | undefined
  }[]
}

export interface InitiatePaymentBody {
  reservation_id: string
  user_locale: string
  country_code: string
}

export interface InitiatePaymentResponse {
  provider: string
  adapter: string
  url: string
  reference: string
}

export interface PostReservationResponse {
  errors?: {
    message: string
  }[]
}

export enum PaymentStatus {
  Pending = 0,
  Paid = 1,
  Failed = 2,
  Aborted = 3,
  Authorized = 4,
}

export interface Payment {
  reference: string
  status: PaymentStatus
}

export default class {
  pageId: string;

  businessSlug: string;

  apiOverride: string;

  env: string;

  constructor(businessSlug, pageId, apiOverride) {
    this.pageId = pageId;
    this.businessSlug = businessSlug;
    this.apiOverride = apiOverride;

    const regex = /(?:-(?<env>\w+))?$/;
    const match = regex.exec(businessSlug);

    this.env = environments[match ? match.groups.env : environments.prod] || environments.prod;
  }

  async call<Type, Error = unknown>(
    method: Method,
    endpoint: string,
    {
      params = {},
      body = undefined,
      headers = {},
      signal = undefined,
    }: ExtraCallParams = {},
  ): Promise<Type> {
    const subdomain = this.env === environments.prod ? '' : `${this.env}.`;
    const host = this.apiOverride || `https://${subdomain}api.booker25.com`;
    const url = `${host}/api/v2/pages/${this.pageId}${endpoint}${genQueryParams(params)}`;

    const init: RequestInit = {
      method,
      signal,
      cache: 'no-store',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'X-Booker25-Business': this.businessSlug,
        'X-From-Widget': 'true',
        ...headers,
      },
    };

    if (['PUT', 'POST'].includes(method)) {
      init.body = body === undefined ? '' : JSON.stringify(body);
    }

    const response = await fetch(url, init);
    if (!response.ok) {
      throw await (response.json() as Promise<Error>);
    }

    if (response.status === 204) {
      return;
    }

    // eslint-disable-next-line consistent-return
    return response.json() as Promise<Type>;
  }

  async getPage() {
    return this.call<Page>('GET', '/');
  }

  async getResources() {
    return this.call('GET', '/resources/');
  }

  async getResourceServices(
    start: Dayjs, end: Dayjs,
    resource: string,
  ): Promise<ServiceAvailabilityResponse> {
    return this.call<ServiceAvailabilityResponse>('GET', '/services/', {
      params: {
        resource,
        start: start.toISOString(),
        end: end.toISOString(),
      },
    });
  }

  async postReservation(data: Reservation): Promise<PostReservationResponse> {
    return this.call('POST', '/reservations/', { body: data });
  }

  async getCustomFields() {
    return this.call('GET', '/custom_fields/');
  }

  async getTimeslots(start: string, end: string, resources: string[], reservationType: string = null, groupSize: number = null): Promise<Timeslot> {
    return this.call('POST', '/timeslots/', {
      body: {
        start,
        end,
        resources,
        reservation_type: reservationType,
        group_size: groupSize,
      },
    });
  }

  async getWidgetReservations(date, groupSize, resourceIds) {
    return this.call('GET', '/reservations/widget/', {
      params: {
        date,
        group_size: groupSize,
        resource_ids: resourceIds.join(','),
      },
    });
  }

  async getReservation(reservationId) {
    return this.call('GET', `/reservations/${reservationId}/`);
  }

  async cancelReservation(reservationId): Promise<unknown> {
    return this.call<unknown>('PATCH', `/reservations/${reservationId}/cancel/`);
  }

  async calendarAvailability(start: string, end: string, timezone: string, resources: string[], reservationType: string = null, groupSize: number = null): Promise<CalendarAvailabilityResponse> {
    return this.call<CalendarAvailabilityResponse>('POST', '/calendar_availability/', {
      body: {
        start,
        end,
        timezone,
        resources,
        reservation_type: reservationType,
        group_size: groupSize,
      },
    });
  }

  async initiatePayment(data: InitiatePaymentBody): Promise<InitiatePaymentResponse> {
    return this.call('POST', '/payments/', { body: data });
  }

  async getPayment(paymentReference: string): Promise<Payment> {
    return this.call('GET', `/payments/${paymentReference}/`);
  }

  async getPriceBreakdown(data: Reservation, signal?: AbortSignal): Promise<PriceBreakdown> {
    return this.call('POST', '/reservations/price/', {
      body: data,
      signal,
    });
  }
}
