import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import filterActions from '../../actions/filterActions';
import AccordionButton, { AccordionButtonRef } from '../AccordionButton/AccordionButton';

import GroupIcon from '../../images/attendees.svg';

import './style.scss';
import useReduxKey from '../../hooks/useReduxKey';
import { FilterState } from '../../reducers/filter';

const predefinedGroupSize = {
  topList: [1, 2, 3, 4, 5],
  bottomList: [6, 7, 8, 9, 10],
};

export default ({ disabled, showDetailsOnStart, maxGroupSize }): JSX.Element => {
  const { setGroupSize } = filterActions();
  const [activeId, setActiveId] = useState(null);
  const [customValue, setCustomValue] = useState('');
  const filterState = useReduxKey<FilterState>('filterState');
  const buttonRef = useRef<AccordionButtonRef>();
  const { t } = useTranslation();

  useEffect(() => {
    buttonRef.current.setOpen(showDetailsOnStart);
  }, [showDetailsOnStart]);

  const validCustomValue = useMemo(() => {
    if (customValue === null || customValue.length === 0) {
      return false;
    }

    const value = parseInt(customValue, 10);

    return value >= 1 && (!maxGroupSize || value <= maxGroupSize);
  }, [customValue]);

  function handleOnClickGroupSizeSelected(groupSize) {
    if (activeId === groupSize) {
      setActiveId(null);
      setGroupSize(null);
    } else {
      buttonRef.current.setOpen(false);
      setActiveId(groupSize);
      setGroupSize(groupSize);
    }
  }

  function handleOnChangeCustomValue(value) {
    const newValue = value.length === 0 ? null : value;
    setCustomValue(newValue);
    setActiveId(null);
  }

  function renderGroupSizeEntry(entry) {
    return (
      <li
        key={entry}
        onClick={() => handleOnClickGroupSizeSelected(entry)}
        className={`${activeId === entry ? 'is-active' : ''} ${maxGroupSize && entry > maxGroupSize ? 'is-disabled' : ''}`}
        hidden={maxGroupSize && maxGroupSize <= 5 && entry > 5}
      >
        {entry}
      </li>
    );
  }

  function renderGroupSizes() {
    const groupSizeCanBeMoreThan10 = !maxGroupSize || maxGroupSize > 10;

    return (
      <>
        <ul className="group-sizes">
          {predefinedGroupSize.topList.map((entry) =>
            renderGroupSizeEntry(entry),
          )}
          {predefinedGroupSize.bottomList.map((entry) =>
            renderGroupSizeEntry(entry),
          )}
        </ul>

        <hr className={groupSizeCanBeMoreThan10 ? 'divider' : 'bottom-padding'} />

        <div className="custom-amount-container" hidden={!groupSizeCanBeMoreThan10}>
          <p>{t('larger_groups')}</p>

          <div className="input-field">
            <input
              min={1}
              type="number"
              onChange={(event) =>
                handleOnChangeCustomValue(event.target.value)}
              placeholder={t('amount')}
            />

            <button
              type="button"
              disabled={!validCustomValue}
              onClick={() => {
                buttonRef.current.setOpen(false);
                setGroupSize(parseInt(customValue, 10));
              }}
            >
              {t('submit')}
            </button>
          </div>
        </div>
      </>
    );
  }

  function renderNumberOfAttendees() {
    const { groupSize } = filterState;
    if (groupSize === null || groupSize === 0) {
      return t('group_size');
    }
    return `${groupSize} ${groupSize === 1 ? t('attendee') : t('attendees')}`;
  }

  return (
    <div className="group-size-container">
      <AccordionButton
        ref={buttonRef}
        disabled={disabled}
        label={renderNumberOfAttendees()}
        icon={GroupIcon}
      >
        {renderGroupSizes()}
      </AccordionButton>
    </div>
  );
};
