import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import './styles.scss';

export default function ProcessPending({ processName }): ReactElement {
  const { t } = useTranslation();

  const processLabel = processName === 'booking'
    ? t('process_booking') : t('process_payment');

  return (
    <div className="container-booker-processed">
      <h2>{t('one_moment')}</h2>
      <p>{processLabel}</p>
      <div className="loading-state">
        <div className="spinner" />
      </div>
    </div>
  );
}
