import './Footer.scss';
import { useTranslation } from 'react-i18next';

export default (): JSX.Element => {
  const { t } = useTranslation();
  return (
    <div className="global-footer">
      <a href={`mailto:${t('mailto_email_address')}`} className="email-link">
        {t('mailto_email_address')}
      </a>
      <div className="booker25-logo" />
    </div>
  );
};
