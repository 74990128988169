import { useTranslation } from 'react-i18next';

import serviceActions from '../../actions/serviceActions';

import Button from '../Button/Button';
import QuantityButton from '../QuantityButton';
import { Service } from '../../reducers/services';


interface ServiceActionsProps {
  service: Service;
  serviceIdx: number;
}

export default ({ service, serviceIdx }: ServiceActionsProps): JSX.Element => {
  const quantitativeService = service.quantitative;
  const includedByDefaultService = service.included_by_default;
  const serviceSelected = service.selectedQuantity > 0;
  const nonQuantitativeServiceSelected = (
    !quantitativeService && serviceSelected
  );
  const nonQuantitativeServiceNotSelected = (
    !quantitativeService && !serviceSelected
  );

  const {
    increaseAdditionalServiceQuantity,
    decreaseAdditionalServiceQuantity,
  } = serviceActions();

  const handleServiceQuantityIncrease = () => {
    increaseAdditionalServiceQuantity(serviceIdx);
  };

  const handleServiceQuantityDecrease = () => {
    decreaseAdditionalServiceQuantity(serviceIdx);
  };

  const { t } = useTranslation();

  const remainingCapacity = service.capacity == null
    ? Infinity
    : service.capacity;

  return !includedByDefaultService ? (
    <>
      {quantitativeService && (
        <QuantityButton
          increaseQuantityCallback={handleServiceQuantityIncrease}
          decreaseQuantityCallback={handleServiceQuantityDecrease}
          quantity={service.selectedQuantity}
          quantityLimit={remainingCapacity}
        />
      )}
      {nonQuantitativeServiceSelected && (
        <Button
          onClick={handleServiceQuantityDecrease}
          className="button--small button--secondary"
          label={t('added')}
        />
      )}
      {nonQuantitativeServiceNotSelected && (
        <Button
          onClick={handleServiceQuantityIncrease}
          className="button--small button--white"
          label={t('add')}
        />
      )}
    </>
  ) : (
    <Button
      className="button--small inactive-button"
      label={t('on_the_house')}
      disabled
    />
  );
};
