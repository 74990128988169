import { useEffect, useState, ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import useBooker25 from '../../hooks/useBooker25';
import useReduxKey from '../../hooks/useReduxKey';
import serviceActions from '../../actions/serviceActions';
import { formatServices, groupServices } from '../../utils/services';

import SpaceHeader from '../../components/SpaceHeader';
import SpaceStickyFooter from '../../components/SpaceStickyFooter';
import ServiceList from '../../components/ServiceList';
import ProcessPending from '../../components/ProcessPending/index';

import './styles.scss';
import { FilterState } from '../../reducers/filter';

export default (): ReactElement => {
  const api = useBooker25();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const { showServicesStep, showSelectedResourceStep } = useReduxKey('configuration');
  const { includedServices, additionalServices } = useReduxKey('services');
  const { selectedTimeSlot, selectedSpace,
  } = useReduxKey<FilterState>('filterState');
  const {
    setIncludedServices, setAdditionalServices,
  } = serviceActions();

  const getResourceSevices = async () => {
    setLoading(true);
    const {
      available_services: availableservices,
    } = await api.getResourceServices(
      selectedTimeSlot.dStart, selectedTimeSlot.dEnd,
      selectedSpace.id,
    );
    setLoading(false);

    const formattedServices = formatServices(availableservices);
    const {
      includedServicesGroup, additionalServicesGroup,
    } = groupServices(formattedServices);

    setIncludedServices(includedServicesGroup);
    setAdditionalServices(additionalServicesGroup);
  };

  useEffect(() => {
    const resourceHasNoServices = (
      (includedServices && includedServices.length === 0)
        && (additionalServices && additionalServices.length === 0)
    );

    if (!showServicesStep || resourceHasNoServices) {
      navigate('/notes', { state: { step: 1 }, replace: true });
    }

    if (showServicesStep && (!includedServices && !additionalServices)) {
      getResourceSevices();
    }
  }, [showServicesStep, includedServices, additionalServices]);

  const goBack = () => {
    let navigationSteps = -1;
    if (!showSelectedResourceStep) {
      navigationSteps = -3; // nav back to initial selection page if we are skipping selected resources
    }

    navigate(navigationSteps);
  };

  const renderServices = (
    !loading && (includedServices || additionalServices)
  );
  const renderIncludedServices = (
    includedServices && includedServices.length > 0
  );
  const renderAdditionalServices = (
    additionalServices && additionalServices.length > 0
  );

  return (
    <>
        {renderServices ? (
          <>
            {renderIncludedServices && (
                <>
                    <SpaceHeader onClick={goBack} />
                    <div className="service-list-container">
                        <ServiceList title={t('included_services')} services={includedServices} />
                    </div>
                    <SpaceStickyFooter navigate={navigate} next="/notes" nextStep={1}  />
                </>
            )}
            {renderAdditionalServices && (
                <>
                    <SpaceHeader onClick={goBack} />
                    <div className="service-list-container">
                        <ServiceList title={t('additional_services')} services={additionalServices} />
                    </div>
                    <SpaceStickyFooter navigate={navigate} next="/notes" nextStep={1}  />
                </>
            )}
          </>
        ) : (
          <ProcessPending processName='booking'/>
        )}
    </>
  );
};
