import { ServiceAvailability } from '../services/Booker25';
import { ServicePrototype } from '../types';
import { Service } from '../reducers/services';

export const formatServices = (services: ServiceAvailability[]): Service[] => services.map(
  (svc) => ({
    capacity: svc.remaining_capacity,
    ...svc.service,
    selectedQuantity: svc.service.included_by_default ? 1 : 0,
    price: svc.service.price,
    priceIncl: svc.service.price_incl,
    vatRate: svc.service.vat_rate,
  }),
);

export const groupServices = (
  services: Service[],
): {
  includedServicesGroup: Service[],
  additionalServicesGroup: Service[]
} => {
  const includedServicesGroup = services.filter(
    (svc) => svc.included_by_default,
  );
  const additionalServicesGroup = services.filter(
    (svc) => !svc.included_by_default,
  );

  return {
    includedServicesGroup,
    additionalServicesGroup,
  };
};

export const getSelectedServices = (services: Service[]): Service[] => services.filter(
  (svc) => svc.selectedQuantity > 0,
);

export const getReservationServices = (services: Service[]): ServicePrototype[] =>
  getSelectedServices(services).map<ServicePrototype>(({ id, selectedQuantity }) => ({
    id,
    quantity: selectedQuantity,
  }));
