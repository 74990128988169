import * as Yup from 'yup';
import { CustomField } from '../reducers/customFields';

export type ValidationSchema = {
  [key: string]: unknown;
}

export function customFieldValidator(customField: Pick<CustomField, 'type' | 'required' | 'hidden'>, requiredFieldMessage: string): unknown {
  let validator;

  switch (customField.type) {
    case 'text':
      validator = Yup.string();
      break;
    case 'number':
      validator = Yup.number();
      break;
    case 'checkbox':
      validator = Yup.boolean();
      break;
    default:
      return undefined;
  }

  if (customField.required && customField.hidden !== true) {
    validator = validator.required(requiredFieldMessage);
  }

  return validator;
}
