import { useMemo } from 'react';
import Booker25 from '../services/Booker25';
import useReduxKey from './useReduxKey';

export default () => {
  const { businessSlug, pageId, apiOverride } = useReduxKey('configuration');
  return useMemo(() => (
    new Booker25(businessSlug, pageId, apiOverride)
  ), [businessSlug, pageId, apiOverride]);
};
