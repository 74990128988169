import useActions from '../hooks/useActions';
import attendees from '../reducers/attendees';

export default () => useActions({
  setAttendees: (formData) => ({
    type: attendees.types.setAttendeesForm,
    payload: formData,
  }),

  resetAttendees: () => ({
    type: attendees.types.resetAttendees,
  }),
});
