import { createContext, PropsWithChildren, useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useReduxKey from '../hooks/useReduxKey';
import dayjs from 'dayjs';

interface LanguageApi {
  languages: string[]
  language: string
  setLanguage: (lang: string) => void
}

export const LanguageContext = createContext<LanguageApi>({
  languages: [],
  language: '',
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setLanguage: () => {},
});

export default function LanguageProvider({ children }: Readonly<PropsWithChildren<{}>> ): JSX.Element {
  const { i18n } = useTranslation();

  const { languages = [], defaultLanguage} = useReduxKey('configuration');

  const [language, setLanguage] = useState<string | undefined>(defaultLanguage ?? undefined);

  useEffect(() => {
    if (language === undefined && defaultLanguage !== undefined) {
      setLanguage(defaultLanguage);
      dayjs.locale(defaultLanguage);
    }
  }, [defaultLanguage, language]);

  useEffect(() => {
    if (language !== undefined) {
      if (language === navigator.language.split('-')[0] && navigator.language.split('-')[1] !== undefined) {
        const defaultLanguageWithLocale = `${language}-${navigator.language.split('-')[1]}`;
        i18n.changeLanguage(defaultLanguageWithLocale);
        dayjs.locale(defaultLanguageWithLocale);
        return;
      }

      i18n.changeLanguage(language);
      dayjs.locale(language);
    }
  }, [language]);

  return useMemo(() => (
      <LanguageContext.Provider
        value={{
          languages,
          language,
          setLanguage,
        }}
      >
        {children}
      </LanguageContext.Provider>
    ), [languages, language, setLanguage, children]);
}
