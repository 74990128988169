import createReducer from '../utils/createReducer';

const INITIAL_STATE = {
  timeslots: [],
  timeslotCacheVersion: 1,
};

export default createReducer('timeslots', INITIAL_STATE, {
  setTimeslots: (state, payload) => ({
    ...state,
    timeslots: payload,
  }),
  invalidateTimeslotCache: (state) => ({
    ...state,
    timeslotCacheVersion: (state.timeslotCacheVersion || 1) + 1,
  }),
});
