import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import useReduxKey from '../../hooks/useReduxKey';
import Price from '../Price/Price';

import './style.scss';
import { PriceContext } from '../../contexts/PriceProvider';
import { FilterState, Space } from '../../reducers/filter';

interface ReservationCardSpacePriceSummaryProps {
  space: Space
}

const ReservationCardSpacePriceSummary = ({ space }: ReservationCardSpacePriceSummaryProps) => {
  const { t } = useTranslation();

  const { groupSize } = useReduxKey<FilterState>('filterState');

  const { subtotal, subtotal_incl: subtotalVat } = useContext(PriceContext);
  const { showPriceInclVat } = useReduxKey('configuration');

  return (
    <div className="card-summary-price">
      <h3>{t('space')}</h3>
      <div className="total-overview">
        <div className="overview-row">
          <div className="overview-column">{`${space.name} x ${groupSize} ${groupSize === 1 ? t('attendee') : t('attendees')}`}</div>
          <div className="overview-column">
            <Price value={subtotal} />
          </div>
        </div>
        {showPriceInclVat && (
          <>
            <div className="overview-row">
              <div className="overview-column">{t('vat_21')}</div>
              <div className="overview-column">
                <Price value={subtotalVat - subtotal} />
              </div>
            </div>
            <div className="overview-row title">
              <div className="overview-column">{t('total_incl_vat')}</div>
              <div className="overview-column">
                <Price value={subtotalVat} />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ReservationCardSpacePriceSummary;
