import { parseISO, isDate } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import dayjs from 'dayjs';

type DateFnsParsable = Date | string | dayjs.Dayjs;
type PredefinedFormats = 'time' | 'date' | 'month-year' | 'date-long';

type DateTimeProps = {
  datetime: DateFnsParsable;
  format: PredefinedFormats;
  localeObjects: any,
  locale?: string;
  displayTimezone?: string;
};

const predefinedFormats: Record<PredefinedFormats, string> = {
  'time': 'p', // 3:30 PM | 15:30
  'date': 'P', // 20/08/2024
  'month-year': 'MMMM yyyy', // August 2024
  'date-long': 'PPP', // August 20, 2024
};

/**
 * Formats a date and time according to the provided format and locale
 * @param {DateFnsParsable} datetime - Date and time to be formatted
 * @param {PredefinedFormats} format - Format to be used
 * @param {any} localeObjects - Locale objects
 * @param {string} locale - Locale code
 * @returns {string} - Formatted date and time
 */
export function formatDateTime(datetime: DateFnsParsable, format: PredefinedFormats, localeObjects: any, locale?: string, timezone?: string): string {
  const date = toDate(datetime);
  const timeZone = timezone || dayjs.tz.guess();

  if (!isDate(date) || isNaN(date.getTime())) {
    throw new Error('Invalid date');
  }
  
  const languageCode = locale?.split('-')[0];
  const dateFnsLocale = locale ? localeObjects[languageCode] : undefined;

  // Making sure the time is formatted correctly for en-US
  if(locale === 'en-US' && format === 'time') {
    return dayjs(date).locale(languageCode).format('LT');
  }
  // Making sure the date is formatted correctly for en-US (MM/DD/YYYY)
  if(locale === 'en-US' && format === 'date') {
    return dayjs(date).locale(languageCode).format('L');
  }

  return formatInTimeZone(date, timeZone, predefinedFormats[format], { locale: dateFnsLocale});
}

/**
 * Component that formats a date and time according to the provided format and locale
 * @param {DateTimeProps} props - Component props
 * @returns {JSX.Element} - Formatted date and time
 */
export default function DateTime({ datetime, format, localeObjects, locale, displayTimezone }: Readonly<DateTimeProps>): JSX.Element {
  const formatted = formatDateTime(datetime, format, localeObjects, locale, displayTimezone);
  const date = toDate(datetime);

  if (!isDate(date) || isNaN(date.getTime())) {
    throw new Error('Invalid date');
  }

  const iso = date.toISOString();
  return <time dateTime={iso}>{formatted}</time>;
}

/**
 * Converts various date inputs (ISO string, Date object, dayjs object) to a JavaScript Date object
 * @param {DateInput} datetime - Date input to be converted
 * @returns {Date} - JavaScript Date object
 */
export function toDate(datetime: DateFnsParsable): Date {
  if (typeof datetime === 'string') {
      return parseISO(datetime);
  } else if (dayjs.isDayjs(datetime)) {
      return datetime.toDate();
  } else if (isDate(datetime)) {
      return datetime;
  } else {
      throw new Error('Invalid date input');
  }
}