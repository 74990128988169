import createReducer from '../utils/createReducer';

export interface Days {
  [date: string]: {
    available: boolean
  }
}

export interface Calendar {
  days: Days;
  fetching: boolean;
  month: Date | undefined;
  selectedDay: Date | undefined;
  loadedMonths: string[];
}

const INITIAL_STATE: Calendar = {
  days: {},
  fetching: false,
  month: undefined,
  selectedDay: undefined,
  loadedMonths: [],
};

export default createReducer('calendar', INITIAL_STATE, {
  setCalendarDays: (state: Calendar, payload: Days): Calendar => ({
    ...state,
    days: {
      ...state.days,
      ...payload,
    },
  }),
  addLoadedMonth: (state: Calendar, payload: string) => ({
    ...state,
    loadedMonths: [
      ...state.loadedMonths,
      payload,
    ],
  }),
  setFetching: (state: Calendar, payload: boolean): Calendar => ({
    ...state,
    fetching: payload,
  }),
  setMonth: (state: Calendar, payload: Date): Calendar => ({
    ...state,
    month: payload,
  }),
  setSelectedDay: (state: Calendar, payload: Date): Calendar => ({
    ...state,
    selectedDay: payload,
  }),
});
