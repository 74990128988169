import { useEffect } from 'react';

/**
 * createMessageHandler - Handles post message events
 * @param {Object.<string, function>} handlers - Type based handlers. For each message type
 * handle the optional payload with a callback
 * @param {Array} allowedOrigins - Array of allowd origins of where to handle the messages from
 * @returns {function({data: {type: *, payload?: *}, source: *, origin?: *}): Promise<undefined>}
 */
export const createMessageHandler = (
  handlers,
  { allowedOrigins } = {},
) => async ({
  data: { type, payload },
  source,
  origin,
}) => {
  if (allowedOrigins && !allowedOrigins.includes(origin)) {
    return;
  }

  let response;
  if (handlers[type]) {
    response = await handlers[type](payload);
  } else if (handlers['*']) {
    response = await handlers['*']({ type, payload });
  }

  if (response) {
    source.postMessage({
      type: 'postMessageResponse',
      payload: { type, response },
    }, origin);
  }
};

/**
 * useMessageReceiver - Add a callback to PostMessageEvents
 * @param {function} callback - Message handler. Can be created with {@link createMessageHandler}
 * @returns {Promise<undefined>} - Promise that resolves when the callback is bound to the
 * event handler
 */
export default (callback) => new Promise((resolve) => {
  // Tried doing this with an async function. Would induce a render loop.

  useEffect(() => {
    window.addEventListener('message', callback, false);
    resolve();
    return () => {
      window.removeEventListener('message', callback, false);
    };
  }, [callback]);
});
