/* eslint-disable no-shadow */
import createPostMessage, { postMessageTypes } from '../postMessageActions';

export enum TrackingEventEnum {
  SCHEDULE_OPEN = 'scheduleOpen',
  SCHEDULE_CLOSE = 'scheduleClose',
  SCHEDULE_LOCATION_FILTER_CHANGED = 'scheduleLocationFilterChanged',
  SCHEDULE_LOCATION_SELECTED = 'scheduleLocationSelected',
  SCHEDULE_RESERVATION_TYPE = 'scheduleReservationType',
  SCHEDULE_RESERVATION_TYPE_SELECTED = 'scheduleReservationTypeSelected',
  SCHEDULE_DATE = 'scheduleDate',
  SCHEDULE_DATE_SELECTED = 'scheduleDateSelected',
  SCHEDULE_TIME = 'scheduleTime',
  SCHEDULE_TIME_SELECTED = 'scheduleTimeSelected',
  SCHEDULE_SPACE = 'scheduleSpace',
  SCHEDULE_SPACE_SELECTED = 'scheduleSpaceSelected',
  SCHEDULE_OVERVIEW = 'scheduleOverview',
  SCHEDULE_NOTES = 'scheduleNotes',
  SCHEDULE_DETAILS = 'scheduleDetails',
  SCHEDULE_ATTENDEES = 'scheduleAttendees',
  SCHEDULE_TERMS_AND_CONDITIONS = 'scheduleTermsAndConditions',
  SCHEDULE_TERMS_AND_CONDITIONS_CHECKED = 'scheduleTermsAndConditionsChecked',
  SCHEDULE_CONFIRM = 'scheduleConfirm',
  SCHEDULE_RESERVATION_SUBMITTED = 'scheduleReservationSubmitted',
  SCHEDULE_RESERVATION = 'scheduleReservation',
  SCHEDULE_RESERVATION_FAILED = 'scheduleReservationFailed',
  SCHEDULE_CANCEL_RESERVATION_SUBMITTED = 'scheduleCancelReservationSubmitted',
  SCHEDULE_CANCEL_RESERVATION_SUCCEEDED = 'scheduleCancelReservationSucceeded',
}

export const postTrackingEvent = (
  trackingEvent: TrackingEventEnum, parentOrigin: string,
  data = {},
): void => {
  window.parent.postMessage(
    createPostMessage(postMessageTypes.gtmEvent, {
      event: trackingEvent,
      data,
    }),
    parentOrigin,
  );
};
