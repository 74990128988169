import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import AccordionButton from '../AccordionButton/AccordionButton';
import LocationIcon from '../../images/location.svg';
import { UseLocationsReturn } from '../../hooks/useLocations';

import './style.scss';

interface LocationPickerProps {
  showDetailsOnStart: boolean;
  locations: UseLocationsReturn;
}

const renderChoices = (choice, rendered = []) => {
  if (!choice || !choice.choices || choice.isFinal) {
    return rendered;
  }

  if (!choice.oneChoice) {
    rendered.push(
      <>
        <ul className="locations-list">
          {choice.choices
            .filter((c) => c !== null)
            .map(({ select, name, isSelected }) => (
              <li
                key={name}
                className={isSelected && 'is-active'}
                onClick={select}
              >
                {name}
              </li>
            ))}
        </ul>
      </>,
    );
  }

  return renderChoices(choice.choices[choice.selectedIndex], rendered);
};

export default ({ showDetailsOnStart, locations }: LocationPickerProps) => {
  const { t } = useTranslation();
  const buttonRef = useRef();
  const subCategoryRefs = useRef([]);

  useEffect(() => {
    if (buttonRef.current !== undefined) {
      // @ts-ignore
      buttonRef.current.setOpen(showDetailsOnStart);
    }
  }, [showDetailsOnStart, buttonRef.current]);

  const assignRef = (el, index) => {
    subCategoryRefs.current[index] = el;
  };

  const selectLocation = (index, callback) => {
    setTimeout(() => {
      if ((index + 1) < subCategoryRefs.current.length) {
        subCategoryRefs.current[index + 1].scrollIntoView({ behavior: 'smooth' });
      }
    }, 100);
    callback();
  };

  return (
    locations && (
      <div className="locations-container">
        <AccordionButton
          label={locations.selected?.name || t('location')}
          ref={buttonRef}
          icon={LocationIcon}
          hidden={locations.options.length === 0}
        >
          {locations.options?.map((locationOptions, index) => (
            <ul ref={(el) => assignRef(el, index)} className="locations-list" key={index}>
              {index > 0 && (
                <li className="sub-category">
                  {t(
                    locationOptions[0].resource_type_name.toLowerCase().replace(' ', '_'),
                    locationOptions[0].resource_type_name)}
                </li>
              )}
              {locationOptions.map(({ name, isSelected, select }) => (
                <li
                  key={name}
                  className={classNames({
                    'is-active': isSelected,
                  })}
                  onClick={() => { selectLocation(index, select); }}
                >
                  <span className="checkbox" />
                  {name}
                </li>
              ))}
            </ul>
          ))}
        </AccordionButton>
      </div>
    )
  );
};
