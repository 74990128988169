import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

interface TermsAndConditionsProps {
  termsChecked: boolean;
  handleOnClickTermsChecked: (boolean) => void;
}

export default ({
  termsChecked,
  handleOnClickTermsChecked,
}: TermsAndConditionsProps): ReactElement => {
  const { t } = useTranslation();
  const link = t('terms_conditions_url');

  return (
    <div>
      <label className="checkbox-container" htmlFor="termsCheckbox">
        {
          link
            ? (
              <a
                href={link}
                target="_blank"
                rel="noopener noreferrer"
                className={classNames({
                  'input-label': true,
                  link,
                })}
              >
                {t('terms_conditions')}
              </a>
            )
            : (
              <span
                className={classNames({
                  'input-label': true,
                  link,
                })}
              >
                {t('terms_conditions')}
              </span>
            )
        }
        <input
          id="termsCheckbox"
          name="termsCheckbox"
          type="checkbox"
          checked={termsChecked}
          onChange={() => handleOnClickTermsChecked(!termsChecked)}
        />
        <span className="checkmark" data-cy="termsCheckbox" />
      </label>
    </div>
  );
};
