import { useField } from 'formik';
import { useTranslation } from 'react-i18next';

import './style.scss';

type InputFieldProps = {
  name: string;
  label: string;
  type: string;
  required?: boolean;
  placeholder?: string;
  hidden?: boolean;
};

const InputField = (props: InputFieldProps): JSX.Element => {
  const [field, { error, touched }] = useField(props);
  const { t } = useTranslation();

  return (
    <div className={`${touched && error ? 'has-error input-field' : 'input-field'} ${props.hidden ? 'hidden' : ''}`}>
      <div className="input-field__flex">
        <label className="input-label" htmlFor={props.name}>
          {props.label}
        </label>
        {props.required ? null : t('optional')}
      </div>

      <input id={props.name} {...props} {...field} />

      <span>
        {touched && error && (
          <span className="error-message">{error}</span>
        )}
      </span>
    </div>
  );
};

InputField.defaultProps = {
  required: null,
  placeholder: null,
  hidden: null,
};

export default InputField;
