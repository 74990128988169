import createReducer from '../utils/createReducer';

export interface Pricing {
  priceCalculationProcessed: boolean;
}

const INITAL_STATE: Pricing = {
  priceCalculationProcessed: false,
};

export default createReducer('pricing', INITAL_STATE, {
  setPriceCalculationProcessed: (state, payload) => ({ ...state, priceCalculationProcessed: payload }),
});
