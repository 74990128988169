import React, { ReactElement } from 'react';
import useReduxKey from '../../hooks/useReduxKey';

import Price from '../Price/Price';
import CardAction from '../ServiceCardAction';

import './styles.scss';
import { Service } from '../../reducers/services';

interface ServiceCardProps {
  service: Service;
  serviceIdx: number;
}

const ServiceCard = ({ service, serviceIdx }: ServiceCardProps): ReactElement => {
  const {
    showPriceInclVat, showPayments,
  } = useReduxKey('configuration');

  return (
    <div className="service-detail">
      {service.image_url && (
      <div className="service-img">
        <img src={service.image_url} alt={service.name} />
      </div>
      )}
      <div className="service-title">
        <h2>{service.name}</h2>
        <h3>{service.description}</h3>
      </div>
      <div className="service-action">
        <div>
          {showPayments && <Price value={showPriceInclVat ? service.priceIncl : service.price} />}
        </div>
        <CardAction service={service} serviceIdx={serviceIdx} />
      </div>
    </div>
  );
};

export default ServiceCard;
