import useBooker25 from '../hooks/useBooker25';
import useActions from '../hooks/useActions';
import resources from '../reducers/resources';

const mapResources = (children, map = {}, parent = null) => {
  if (!children) {
    return map;
  }

  return children.reduce((mapped, { children: subChildren, ...child }) => ({
    ...mapped,
    [child.id]: {
      ...child,
      parent,
    },
    ...mapResources(subChildren, mapped, child.id),
  }), {});
};

export default () => {
  const api = useBooker25();
  return useActions({
    fetchResources: () => async (dispatch) => {
      // @ts-ignore
      const { items } = await api.getResources();
      dispatch({
        type: resources.types.setResourceTree,
        payload: items,
      });

      dispatch({
        type: resources.types.setResourceMap,
        payload: mapResources(items),
      });
    },
  });
};
