import { ReactElement } from 'react';
import useReduxKey from '../../hooks/useReduxKey';
import { getSelectedServices } from '../../utils/services';

import SpacePriceSummary from '../ReservationCardSpacePriceSummary';
import ServicesPriceSummary from '../ReservationCardServicesPriceSummary';
import { FilterState } from '../../reducers/filter';
import { Configuration } from '../../reducers/configuration';
import { Services } from '../../reducers/services';


const PaymentSummary = (): ReactElement => {
  const { selectedSpace } = useReduxKey<FilterState>('filterState');
  const { showServicesStep } = useReduxKey<Configuration>('configuration');
  const { additionalServices } = useReduxKey<Services>('services');

  const selectedServices = additionalServices
    ? getSelectedServices(additionalServices)
    : null;

  const renderServicesSummary = (
    showServicesStep && (selectedServices && selectedServices.length > 0)
  );

  const renderSpaceSummary = selectedSpace?.id;

  return (
    <>
      {renderSpaceSummary && <SpacePriceSummary space={selectedSpace} />}
      {renderServicesSummary && <ServicesPriceSummary services={selectedServices} />}
    </>
  );
};

export default PaymentSummary;
