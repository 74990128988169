import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { composeWithDevTools } from '@redux-devtools/extension'
import { applyMiddleware, legacy_createStore as createStore } from 'redux';
import { thunk } from 'redux-thunk';

import rootReducer from './reducers';
import App from './App';

import './index.scss';
import PriceProvider from './contexts/PriceProvider';
import LanguageProvider from './contexts/LanguageProvider';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const composeEnhancers = composeWithDevTools({});

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk)),
);

i18n.use(initReactI18next).init({
  debug: true,
  fallbackLng: 'en',
  keySeparator: false,
  resources: {},
});

const container = document.getElementById('booker25-widget');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <LanguageProvider>
        <PriceProvider>
          <App />
        </PriceProvider>
      </LanguageProvider>
    </Provider>
  </React.StrictMode>
);
