import { useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { MappedDispatch } from '../types';

export default function useActions<Type = MappedDispatch>(actions: MappedDispatch): Type {
  const dispatch = useDispatch();

  return useMemo(
    () =>
      Object.entries(actions).reduce(
        (mappedActions, [name, creator]) => ({
          ...mappedActions,
          [name]: (...args) => dispatch(creator(...args)),
        }),
        {} as Type,
      ),
    [actions],
  );
};
