import React from 'react';

import useReduxKey from '../../hooks/useReduxKey';
import createPostMessage, { postMessageTypes } from '../../postMessageActions';
import { ReactComponent as CloseIcon } from '../../images/close.svg';

import './styles.scss';

interface ConfirmHeaderProps {
  title?: string;
}

const ConfirmHeader = ({ title }: ConfirmHeaderProps) => {
  const { parentOrigin, showCloseButton } = useReduxKey(
    'configuration',
  );

  return (
    <div className="confirm-header">
      {showCloseButton ? (
        <div
          role="button"
          onClick={() => {
            window.parent.postMessage(
              createPostMessage(postMessageTypes.toggle),
              parentOrigin,
            );
          }}
          className="close-button"
        >
          <CloseIcon />
        </div>
      ) : (
        <div />
      )}
      <h2>{title}</h2>
    </div>
  );
};

export default ConfirmHeader;
