/* eslint-disable no-mixed-operators */
/**
 *
 * @source  https://gist.github.com/renancouto/4675192
 * @example
 *      LightenColor("#ff3333", 10)    // 10% lighter color
 *      LightenColor("#ff3333", -10)   // 10% darker color
 *
 * @param {*} color
 * @param {*} percent
 */
export function LightenColor(color: string, percent: number): string {
  const num = parseInt(color.replace('#', ''), 16);
  const adjustment = Math.round(2.55 * percent);
  const originalRed = ((num / 65536) % 256);
  const originalGreen = ((num / 256) % 256);
  const originalBlue = (num % 256);

  const red = Math.max(0, Math.min(255, originalRed + adjustment));
  const green = Math.max(0, Math.min(255, originalGreen + adjustment));
  const blue = Math.max(0, Math.min(255, originalBlue + adjustment));

  return (0x1000000 + red * 0x10000 + green * 0x100 + blue).toString(16).slice(1);
}

export const parseHexColor = (rgb: string): [number, number, number] => {
  const hexMatcher = /^#(?:([0-9a-fA-F]{2})([0-9a-fA-F]{2})([0-9a-fA-F]{2})|([0-9a-fA-F])([0-9a-fA-F])([0-9a-fA-F]))$/;

  const match = hexMatcher.exec(rgb);
  if (!match) {
    throw new Error('Not an hex color');
  }

  const r = parseInt(match[1] || `${match[4]}${match[4]}`, 16);
  const g = parseInt(match[2] || `${match[5]}${match[5]}`, 16);
  const b = parseInt(match[3] || `${match[6]}${match[6]}`, 16);

  return [r, g, b];
};

/* Calculates the relative luminance
 *  see: https://www.w3.org/TR/WCAG20/#relativeluminancedef
 */
export const calculateRelativeLuminance = (rgb: string): number => {
  const hexColor = parseHexColor(rgb);

  // convert to sRGB values
  const sRGB = hexColor.map((v) => v / 255);

  // calculate luminance multipliers
  const [r, g, b] = sRGB.map((v) => (
    v <= 0.03928
      ? v / 12.92
      : (((v + 0.055) / 1.055) ** 2.4)
  ));
  return 0.2126 * r + 0.7152 * g + 0.0722 * b;
};

/* Calculate the contrast ratio
 *  see: https://www.w3.org/TR/WCAG20/#contrast-ratiodef
 */
export const calculateContrastRatio = (rgbLight: string, rgbDark: string): number => {
  const luminanceLight = calculateRelativeLuminance(rgbLight);
  const luminanceDark = calculateRelativeLuminance(rgbDark);

  return (luminanceLight + 0.05) / (luminanceDark + 0.05);
};

export const bestFontColor = (backgroundColor: string, fontColorLight = '#FFFFFF', fontColorDark = '#000000'): string => {
  const contrastLight = calculateContrastRatio(fontColorLight, backgroundColor);
  const contrastDark = calculateContrastRatio(backgroundColor, fontColorDark);

  return contrastLight < contrastDark
    ? fontColorDark
    : fontColorLight;
};
