import { useEffect, useState, ReactElement } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { TrackingEventEnum } from '../../utils/analytics';
import useReduxKey from '../../hooks/useReduxKey';

import CheckoutHeader from './CheckoutHeader';
import Button from '../../components/Button/Button';
import TermsAndConditions from '../../components/TermsAndConditions';
import SpaceStickyFooter from '../../components/SpaceStickyFooter';
import BookingPending from '../../components/ProcessPending/index';

import './TermsAndConditions.scss';

import useAnalytics from '../../hooks/useAnalytics';
import { FilterState } from '../../reducers/filter';
import { Pricing } from '../../reducers/pricing';

export default function TermsAndConditionsPage(): ReactElement {
  const [termsChecked, setTermsChecked] = useState(false);
  const { selectedSpace } = useReduxKey<FilterState>('filterState');
  const { t } = useTranslation();
  const { trackEvent } = useAnalytics();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { priceCalculationProcessed } = useReduxKey<Pricing>('pricing');

  const { showPayments, showTermsStep } = useReduxKey('configuration');

  useEffect(() => {
    if (!showTermsStep) {
      navigate('/overview', { state: { step: state.step }, replace: true });
    } else {
      trackEvent(TrackingEventEnum.SCHEDULE_TERMS_AND_CONDITIONS);
    }
  }, [showTermsStep, navigate, state.step, trackEvent]);

  function handleOnClickTermsChecked(value: boolean) {
    setTermsChecked(value);
    trackEvent(TrackingEventEnum.SCHEDULE_TERMS_AND_CONDITIONS_CHECKED, { value });
  }

  const shouldDisplayPage = !showPayments || (showPayments && priceCalculationProcessed);

  if (shouldDisplayPage) {
    return selectedSpace ? (
        <>
          <CheckoutHeader step={state.step} />
          <div className="terms-container">
            {showPayments && (
              <>
                <h2>{t('billing')}</h2>
                <p>{t('charged_afterwards')}</p>
                <p>{t('payment_invoice')}</p>
              </>
            )}
            <TermsAndConditions
              termsChecked={termsChecked}
              handleOnClickTermsChecked={handleOnClickTermsChecked}
            />
          </div>
          <SpaceStickyFooter navigate={navigate} next="/overview" nextStep={state.step + 1} disableNext={!termsChecked} />
          {t('cancellation_policy_url') && (
            <div className="refund-and-cancellation">
              <p>{t('refund')}</p>
              <Button
                onClick={() => window.open(t('cancellation_policy_url'))}
                label={t('cancellation_policy')}
                className="button--large button--grey"
              />
            </div>
          )}
        </>
      ) : (
        <span>{t('coming_up')}</span>
      );
  } else {
    return (
      <BookingPending processName="booking" />
    );
  }
}