const generatePaymentRedirectBlob = (reactComponentHtml: string): Blob => {
    const fullPageHtml = `
      <!DOCTYPE html>
      <html lang="en">
      <head>
          <meta charset="UTF-8">
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          <title>Redirecting to payment platform...</title>
          <style>
            @keyframes spin {
                0% { transform: rotate(0deg); }
                100% { transform: rotate(360deg); }
            }

            .payment-redirect-container {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100vh;
            flex-direction: column;
            text-align: center;
            background-color: #f8f9fa;
            color: #333;
            font-family: Arial, sans-serif;
            gap: 2rem;
            }

            .payment-redirect-primary-message {
            font-size: 1.5rem;
            margin-bottom: 0.5rem;
            font-weight: bold;
            }

            .payment-redirect-secondary-message {
            font-size: 1rem;
            color: #666;
            }

            .payment-redirect-spinner {
            width: 50px;
            height: 50px;
            border-top: 5px solid #333;
            border-right: 5px solid transparent;
            border-radius: 50%;
            animation: spin 1s linear infinite;
            }
          </style>
      </head>
      <body>
          <div id="app">${reactComponentHtml}</div>
          <script>
              // JavaScript logic to listen for changes in localStorage
              const checkForRedirectURL = () => {
                  const redirectURL = localStorage.getItem("redirectURL");
                  if (redirectURL) {
                      localStorage.removeItem("redirectURL");
                      window.location.href = redirectURL;
                  }
              };
  
              checkForRedirectURL();
  
              // Polling fallback (in case 'storage' event doesn't fire)
              setInterval(checkForRedirectURL, 1000);
  
              // Listen for storage events (not always supported)
              window.addEventListener("storage", (event) => {
                  if (event.key === "redirectURL" && event.newValue) {
                      localStorage.removeItem("redirectURL");
                      window.location.href = event.newValue;
                  }
              });
          </script>
      </body>
      </html>
    `;

    return new Blob([fullPageHtml], { type: "text/html" });
}

export default generatePaymentRedirectBlob;