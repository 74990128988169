/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useCallback, useEffect, useMemo, useState, } from 'react';
import { MemoryRouter, Routes, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import localeData from 'dayjs/plugin/localeData';
import Filter from './pages/Filter';
import Spaces from './pages/Spaces';
import Space from './pages/Space';
import Services from './pages/Services';
import Overview from './pages/Checkout/Overview';
import Notes from './pages/Checkout/Notes';
import ClientDetails from './pages/Checkout/Details';
import TermsAndConditions from './pages/Checkout/TermsAndConditions';
import BookingProgress from './pages/BookingProgress/index';
import GlobalFooter from './components/global/Footer';
import useMessageReceiver, { createMessageHandler } from './hooks/useMessageReceiver';
import createPostMessage, { postMessageTypes } from './postMessageActions';
import configurationActions from './actions/configurationActions';
import useReduxKey from './hooks/useReduxKey';
import reservationActions from './actions/reservationActions';
import userActions from './actions/userActions';
import useAsyncEffect from './hooks/useAsyncEffect';
import ReservationDetail from './pages/ReservationDetail';
import './App.scss';

import CalendarProvider from './contexts/CalendarProvider';
import { RootState } from './reducers';
import Attendees from './pages/Checkout/Attendees';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(localizedFormat);
dayjs.extend(localeData);

dayjs.tz.guess();

function App() : JSX.Element {
  const [loading, setLoading] = useState(true);
  const [showReservationDetail, setShowReservationDetail] = useState(false);
  const { fetchConfiguration, setWidgetOrigin, updateConfiguration } = configurationActions();
  const { fetchReservation } = reservationActions();
  const { setUserInteractedWithTheWidget } = userActions();

  const { searchParams } = useMemo(() => new URL(window.location.href), []);

  useAsyncEffect(async () => {
    setLoading(true);

    await fetchConfiguration(
      searchParams.get('business'),
      searchParams.get('pageid'),
      searchParams.get('apioverride'),
      searchParams.get('contactid'),
      searchParams.get('leadid'),
      searchParams.get('accountid'),
    );
  }, []);

  const pageId = useSelector(({ configuration }: RootState) => configuration.pageId);
  useAsyncEffect(async () => {
    if (pageId) {
      if (searchParams.has('reservation')) {
        await fetchReservation(searchParams.get('reservation'));
        setShowReservationDetail(true);
      }

      setLoading(false);
    }
  }, [pageId]);

  const { widgetButton, parentOrigin } = useReduxKey('configuration');
  useEffect(() => {
    if (parentOrigin && widgetButton) {
      window.parent.postMessage(
        createPostMessage(postMessageTypes.setup, {
          widgetButton,
        }),
        parentOrigin,
      );

      if (widgetButton.open_by_default || searchParams.has('reservation')) {
        window.parent.postMessage(
          createPostMessage(postMessageTypes.open),
          parentOrigin,
        );
      }
    }
  }, [widgetButton, parentOrigin]);

  const loadPage = useCallback(async ({ origin }) => {
    setWidgetOrigin(origin);
  }, []);

  const updateConfig = useCallback(async ({ showCloseButton = undefined }) => {
    if (showCloseButton !== undefined) {
      updateConfiguration({ showCloseButton });
    }
  }, []);

  const messageHandler = useMemo(
    () =>
      createMessageHandler({
        [postMessageTypes.setup]: loadPage,
        [postMessageTypes.config]: updateConfig,
      }),
    [],
  );

  useMessageReceiver(messageHandler).then(() => {
    window.parent.postMessage(createPostMessage(postMessageTypes.ready), '*');
  });

  const handleUserInteractedWithTheWidget = () => {
    setUserInteractedWithTheWidget(true);
  };

  return !loading
    ? (
      <div
        className="widget-container"
        onKeyDown={handleUserInteractedWithTheWidget}
        onMouseDown={handleUserInteractedWithTheWidget}
        onTouchStart={handleUserInteractedWithTheWidget}
      >
        <CalendarProvider>
          <MemoryRouter
            initialEntries={[
              showReservationDetail ? '/reservationDetail' : '/',
            ]}
          >
            <Routes>
              <Route path="/" element={<Filter />} />
              <Route path="/spaces" element={<Spaces />} />
              <Route path="/space/:id" element={<Space />} />
              <Route path="/services" element={<Services />} />
              <Route path="/overview" element={<Overview />} />
              <Route path="/notes" element={<Notes />} />
              <Route path="/clientDetails" element={<ClientDetails />} />
              <Route path="/attendeeDetails" element={<Attendees />} />
              <Route path="/termsAndConditions" element={<TermsAndConditions />} />
              <Route path="/bookingProgress" element={<BookingProgress />} />
              <Route path="/reservationDetail" element={<ReservationDetail />} />
            </Routes>
          </MemoryRouter>
          <GlobalFooter />
        </CalendarProvider>
      </div>
    )
    : (
      <div className="loading-state">
        <div className="spinner" />
      </div>
    );
}

export default App;
