import React, { ButtonHTMLAttributes } from 'react';

import './styles.scss';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  backgroundColor?: string;
  iconLeft?: string;
  iconRight?: string;
  label: string|JSX.Element;
  fullWidth?: boolean;
}

const Button = ({
  backgroundColor,
  iconLeft,
  iconRight,
  label,
  ...props
}: ButtonProps) => (
  <button
    type={props.type}
    style={backgroundColor && { backgroundColor }}
    {...props}
  >
    {iconLeft && (
    <div
      className="icon-container"
      style={{ backgroundImage: `url(${iconLeft})` }}
    />
    )}

    {label}

    {iconRight && (
    <div
      className="icon-container"
      style={{ backgroundImage: `url(${iconRight})` }}
    />
    )}
  </button>
);

export default Button;
