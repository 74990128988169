import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useEffect, useMemo, useRef, useState } from 'react';
import * as Yup from 'yup';
import { RootState } from '../../reducers';
import useReduxKey from '../../hooks/useReduxKey';
import CheckoutHeader from './CheckoutHeader';
import SpaceStickyFooter from '../../components/SpaceStickyFooter';
import useAnalytics from '../../hooks/useAnalytics';
import attendeesActions from '../../actions/attendeesActions';
import { TrackingEventEnum } from '../../utils/analytics';
import { CustomField } from '../../reducers/customFields';
import { customFieldValidator } from '../../utils/customFields';
import { FieldArray, Form, Formik } from 'formik';
import InputField from '../../components/InputField/InputField';

function addAttendees(attendeeFields, groupSize, initialValues, setInitialValues) {
  if (attendeeFields) {
    const values = attendeeFields.reduce((obj, customField) => ({ ...obj, [customField.salesforce_field_name]: '' }), {});
    const numAttendees = groupSize >= 1 ? groupSize : 0;
    const attendees = Array.from({ length: numAttendees }, () => ({ ...values }));

    const newInitialValues = {
      ...initialValues,
      attendees,
    };
    setInitialValues(newInitialValues);
  }
}

function useValidation(requiredFieldMessage: string,
  invalidFieldMessage: string,
  groupSize = 0, perAttendeeFields: CustomField[] = null) {
  const attendeeSchema = perAttendeeFields ? Yup.object().shape(
    perAttendeeFields.reduce((schema, f) => {
      const validator = customFieldValidator(f, requiredFieldMessage);
      if (validator) {
        return {
          ...schema,
          [f.salesforce_field_name]: validator,
        };
      }
      return schema;
    }, {})) : undefined;
  const attendeesRules = (perAttendeeFields?.length) ? {
    attendees: Yup.array().of(attendeeSchema).length(groupSize >= 1 ? groupSize : 0).required(),
  } : {};

  return useMemo(
    () =>
      Yup.object().shape(attendeesRules),
    [perAttendeeFields],
  );
}

export default function Attendees(): JSX.Element {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { attendees } = useSelector(
    (rootState: RootState) => rootState,
  );
  const {
    groupSize,
  } = useReduxKey('filterState');

  const formRef = useRef(null);
  const { setAttendees } = attendeesActions();
  const [initialValues, setInitialValues] = useState(attendees.form);
  const { trackEvent } = useAnalytics();
  const { perAttendeeFields, usePerAttendeeFields } = useReduxKey('configuration');
  const invalidFieldMessage = t('invalid_value', 'Invalid value');
  const requiredFieldMessage = t('required_field', 'Required field');
  const { state } = useLocation();

  useEffect(() => {
    if (!usePerAttendeeFields) {
      navigate('/termsAndConditions', { state: { step: state.step }, replace: true });
    }
  }, [usePerAttendeeFields]);

  const handleSubmit = (values) => {
    setAttendees(values);
    navigate('/termsAndConditions', { state: { step: state.step + 1 } });
  };

  const fireSubmitAction = async () => {
    if (formRef.current) {
      await formRef.current.validateForm();

      if (!formRef.current.isValid) {
        // Render form errors manually
        const { errors } = formRef.current;

        formRef.current.setTouched(errors, true);
      } else {
        formRef.current.handleSubmit();
      }
    }
  };

  useEffect(() => {
    addAttendees(perAttendeeFields, groupSize, initialValues, setInitialValues);
    trackEvent(TrackingEventEnum.SCHEDULE_ATTENDEES);
  }, []);

  const validationSchema = useValidation(requiredFieldMessage,
    invalidFieldMessage,
    groupSize, usePerAttendeeFields ? perAttendeeFields : null,
  );


  return (
    <>
      <CheckoutHeader step={state.step} />

      <div className="form-container">
        <Formik
          innerRef={formRef}
          initialValues={initialValues}
          enableReinitialize
          onSubmit={(values) => handleSubmit(values)}
          validationSchema={validationSchema}
        >
          {(props) => (
            <Form onSubmit={props.handleSubmit} className="details_form">
              <div className="client-contact-container">
                {usePerAttendeeFields && (
                  <FieldArray name="attendees">
                    {() => (
                      <>
                        {((props.values.attendees || []) as unknown[]).length > 0
                          && (props.values.attendees as unknown[]).map((attendee, index) => (
                            // eslint-disable-next-line react/no-array-index-key
                            <div className="attendee-details" key={`attendee.${index}`}>
                              <h2>{t('attendee_details', { number: index + 1 })}</h2>

                              {perAttendeeFields.map((attendeeField) => (
                                <InputField
                                  name={`attendees.${index}.${attendeeField.salesforce_field_name}`}
                                  key={`attendees.${index}.${attendeeField.salesforce_field_name}`}
                                  required={!attendeeField.hidden && attendeeField.required}
                                  type={attendeeField.type}
                                  hidden={attendeeField.hidden}
                                  label={t(`custom_field___${attendeeField.salesforce_field_name.toLowerCase()}`, attendeeField.label).toString()}
                                />
                              ))}
                            </div>
                          ))}
                      </>
                    )}
                  </FieldArray>
                )}
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <SpaceStickyFooter navigate={navigate} next="/termsAndConditions" nextStep={state.step + 1} actionCallback={fireSubmitAction} />
    </>

  );
}
